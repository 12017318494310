
import React, { Fragment } from 'react';
import { Route, NavLink, HashRouter, BrowserRouter } from 'react-router-dom';
import '../CommonStyles/Common.css';
const SecondNavbar = () => {

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav mr-auto" id="secondNav" >
                <li >
                  <NavLink exact to="/Home" className="nav-link" >Home </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/ManifestTab" className="nav-link" >Manifest</NavLink>
                </li>
               {/*  <li className="nav-item">
                  <NavLink to="/Dashboard" className="nav-link" >Dashboard</NavLink>
                </li>
                <li className="nav-item dropdown"> 
                  <a  className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Admin
                 </a >
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <NavLink to="/Admin" className="dropdown-item" >Admin</NavLink>
                    <NavLink to="/Another" className="dropdown-item" >Another</NavLink>
                    <NavLink to="/Another2" className="dropdown-item" >Another2</NavLink>
                  </div>
                </li>*/}
              </ul>
              {/* <span className="navbar-text">
                Placeholder
              </span> */}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
export default SecondNavbar;