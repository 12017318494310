import React from 'react';
import axios from 'axios';
//import * as shipmentConst from '../AppConstant/ShippingConstants';
import * as UrlConfig from '../AppConstant/UrlConfig';
export const UpdatePostage = async (postageUpdate) => {
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('inside the UpdatePostage  method');
    
    try {
            const response = await axios.post(UrlConfig.SWITCH_COURIER_URL
                ,JSON.stringify(postageUpdate)
                ,{
                    headers:{'Content-Type':'application/json', "Authorization":`Bearer ${authToken}`}
                }
            )
    return response;
    } catch (exception) {
        return exception;
    }
}



//TODO:Nevin, this needs to be cleaned up if the above method works fine 
export const UpdatePostage_old = async (postageUpdate) => {
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('inside the UpdatePostage  method');
    
    try {
            const response = await axios.post(UrlConfig.UPDATE_POSATGE_URL
                ,JSON.stringify(postageUpdate)
                ,{
                    headers:{'Content-Type':'application/json', "Authorization":`Bearer ${authToken}`}
                }
            )
    return response;
    } catch (exception) {
        return exception;
    }
}