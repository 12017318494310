import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import * as shippingConst from '../AppConstant/ShippingConstants';
import * as OrderSummaryService from './OrderSummaryService';
import '../CommonStyles/Common.css';
import print from 'print-js';
import printJS from 'print-js';
const ManifestResponseModal = (props) => {
    console.log('ManifestResponseModal  props',props);
    const fontStyle = {
        fontWeight:'bolder'
    }
    const downLoadHandler = () => {
        OrderSummaryService.DownLoadPdf(props.message, props.location,props.courier).then(
            response => {
                if (response.status === 200) {
                    console.log('downLoadHandler 200 response',response);
                    console.log('downLoadHandler 200 response.data',response.data);
                    var blob = new Blob([response.data], {type: 'application/pdf'});
                    const url = window.URL.createObjectURL(blob);
                    printJS(url);
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    //link.setAttribute('download', `${props.orderNo}` + '.pdf');
                    //document.body.appendChild(link);
                    link.click();
                }

            })


    }



    return (
        <Modal centered show={props.show} onHide={props.closeModal}  >
            <Modal.Header closeButton>
            <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-center" > <FontAwesomeIcon icon={faCheckCircle} color="green" size="3x"> </FontAwesomeIcon></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <div className="d-flex justify-content-center" style={fontStyle}> Manifest Created : {props.message}</div>
                        </div>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
              {/*   <div className="d-flex justify-content-center">
                    <FontAwesomeIcon centered icon={faCheckCircle} color="green" size="2x"> </FontAwesomeIcon>
                    </div> */}
                

                <br></br>
                <div className="d-flex justify-content-center"><FontAwesomeIcon centered icon={faDownload}   className="cursorPointer"  color="black" onClick={downLoadHandler} size="2x"> </FontAwesomeIcon></div>
                {/* <div className="d-flex justify-content-center"><button onClick={downLoadHandler}  >download Order Summary</button></div> */}
                <div className="d-flex justify-content-center" style={fontStyle}>Download Order Summary</div>
            </Modal.Body>
            <Modal.Footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div  ><button className="btn btn-secondary btn-sm float-right" onClick={props.closeModal} >OK</button></div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default ManifestResponseModal;