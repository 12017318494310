import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import * as shippingConst from '../AppConstant/ShippingConstants';
import './SwitchCourierDropdown.css';


const SwitchCourierDropdown = (props) => {

    const handleSelect = (e) => {
        console.log('Switch Courier selected ', e);

        props.shipmentActionCallback(e);
        /* console.log('AddressDropDown handleSelect e  ',e);
        console.log('AddressDropDown handleSelect e.text  ',e.text);
        console.log('AddressDropDown handleSelect e.target  ',e.target); */
    }


    const GetCourierList = () => {

        var list = (<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {shippingConst.COURIER_SERVICES.map(courier => <a className="dropdown-item" key={courier.displayName} onClick={() => handleSelect(`${courier.displayName}`)} href="#">{courier.displayName}</a>)}
        </div>)
        return list;
    }

    return (

        <div className="dropdown show" id="drpCourier" >
            <a className="btn btn-dark dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Switch Courier
            </a>
            {GetCourierList()}
        </div>

    )
}
export default SwitchCourierDropdown;