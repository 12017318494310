import React, { Component, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import 'react-bootstrap'
import BtProgressBar from './BtProgressBar';
import 'bootstrap/dist/css/bootstrap.css';
import './ProcessingModal.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const CommonProgressModal = (props) => {
console.log("ProcessingModal show",props.show);
    return (

        <Modal   show={props.show}  centered >
            <Modal.Header >
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <div className="d-flex justify-content-center">
               <FontAwesomeIcon  icon={faCog}  className="fa-pulse"  color="black" size="3x"> </FontAwesomeIcon>
               </div>
            </Modal.Body>

            <Modal.Footer>
               
            </Modal.Footer>
        </Modal>
    )
}

export default CommonProgressModal;