import axios from 'axios';
//import * as shipmentConst from '../AppConstant/ShippingConstants';
import * as UrlConfig from '../AppConstant/UrlConfig';
import { format, compareAsc } from 'date-fns';

export const GetShipments = async (toRefresh, fromDate, toDate, courierName) => {
    // console.log('shipmentservice GetShipments', );
    const authToken = sessionStorage.getItem("AuthToken");
    //console.log('shipmentservice GetShipments authToken',authToken);
    try {
        const response = await axios.post(UrlConfig.GET_SHIPMENT_URL,
            {
                isRefresh: toRefresh,
                fromDeliveryDate: format(fromDate, 'dd/MM/yyyy'),
                toDeliveryDate: format(toDate, 'dd/MM/yyyy'),
                courier: courierName
            },
            {
                headers: { 'Authorization': `Bearer ${authToken}` }
            });
        //console.log('shipmentservice GetShipments response',response);
        return response;
    } catch (exception) {
        if (!exception.response) {
            console.log('shipmentservice GetShipments exception', exception);
            return exception;
        }
        return exception.response;
    }
}



export const CreateShipment = async (selectedSalesShipments, courier) => {
    var url = GetCreateShipmentUrl(courier.toLowerCase());
    console.log('GetUrl : ', url);
    console.log('shipmentservice CreateShipment function : ', JSON.stringify(selectedSalesShipments));
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");

    console.log('CreateShipment authToken', authToken);

    try {

        const response = await axios.post(url,
            JSON.stringify(selectedSalesShipments)
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })

        return response;
    } catch (exception) {
        /*  console.log('shipmentservice CreateShipment function exception', JSON.stringify(exception));
         return exception; */
        if (!exception.response) {
            console.log('shipmentservice CreateShipment exception', exception);
            return exception;
        }
        return exception.response;
    }

}


const GetCreateShipmentUrl = (postageCode) => {
    switch (postageCode) {
        case 'startrack':
            return UrlConfig.CREATE_STARTRACK_SHIPMENT_URL;
        case 'ausexpost':
        case 'auspost':
            return UrlConfig.CREATE_AUSPOST_SHIPMENT_URL;
    }
}


export const CreateManifest = async (shipmentsForManifest, user, location, postageCode) => {
    console.log('shipmentservice CreateManifest function location: ', location);
    console.log('shipmentservice CreateManifest function : ', JSON.stringify(shipmentsForManifest));
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    var url = GetCreateManifestUrl(postageCode.toLowerCase());
    var manifestRequest = { shipmentIds: shipmentsForManifest
        , user: user.firstName
        , location: location
        ,courier:postageCode
     };
    console.log('shipmentservice CreateManifest function manifestRequest: ', JSON.stringify(manifestRequest));
    console.log("CreateManifest authToken", authToken);
    try {
        const response = await axios.post(url,
            JSON.stringify(manifestRequest),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
            }
        )
        return response;

    } catch (exception) {
        
        if (!exception.response) {
            console.log('shipmentservice CreateShipment function exception', exception);
            return exception;
        }
        return exception.response;
       /*  
        console.log('shipmentservice CreateManifest function exception', exception);
        return exception; */
    }
}


const GetCreateManifestUrl = (postageCode) => {
    switch (postageCode) {
        case 'startrack':
            return UrlConfig.STARTRACK_CREATE_MANIFEST_URL;
        case 'ausexpost':
        case 'auspost':
            return UrlConfig.CREATE_MANIFEST_URL
    }
}



export const UpdateAddress = async (SalesShipmentViewModel, user) => {
    var paramArray = new Array(SalesShipmentViewModel);
    console.log('shipmentService UpdateAddress function : ', JSON.stringify(SalesShipmentViewModel));
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('shipmentService UpdateAddress paramArray : ', paramArray);
    console.log("shipmentService.UpdateAddress authToken, tokens", authToken, tokens);
    try {
        const response = await axios.post(UrlConfig.UPDATE_ADDRESS_URL,
            JSON.stringify(paramArray),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
                , params: { user: user.firstName }
            }
        )
        console.log("shipmentService.UpdateAddress success", response);
        return response;

    } catch (exception) {

        if (exception.response) {
            console.log("shipmentService.UpdateAddress exception", exception);
            console.log("shipmentService.UpdateAddress exception.response.data", exception.response.data);
            console.log("shipmentService.UpdateAddress exception.response.status", exception.response.status);
            console.log("shipmentService.UpdateAddress exception.response", JSON.stringify(exception.response));
        }
        else if (exception.request) {
            console.log("shipmentService.UpdateAddress exception.request", JSON.stringify(exception.request));
        }
        console.log("shipmentService.UpdateAddress exception", JSON.stringify(exception));
        return exception;
    }
}


export const UpdateWeight = async (dimensionParam) => {

    console.log('shipmentService UpdateWeight function : ', JSON.stringify(dimensionParam));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log("shipmentService.UpdateWeight authToken", authToken);
    try {
        const response = await axios.post(UrlConfig.UPDATE_WEIGHT_URL,
            JSON.stringify(dimensionParam),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
            }
        )
        console.log("shipmentService.UpdatWeight success", response);
        return response;

    } catch (exception) {
        console.log("shipmentService.UpdateWeight exception", JSON.stringify(exception));
        return exception;
    }
}

 export const UpdateSize = async (dimensionParam) => {

    console.log('shipmentService UpdateSize function : ', JSON.stringify(dimensionParam));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log("shipmentService.UpdateSize authToken", authToken);
    try {
        const response = await axios.post(UrlConfig.UPDATE_SIZE_URL,
            JSON.stringify(dimensionParam),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
            }
        )
        console.log("shipmentService.UpdateSize success", response);
        return response;

    } catch (exception) {
        console.log("shipmentService.UpdateSize exception", JSON.stringify(exception));
        return exception;
    }
} 





export const UpdateDimension = async (dimensionParam) => {

    console.log('shipmentService UpdateSize function : ', JSON.stringify(dimensionParam));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log("shipmentService.UpdateSize authToken", authToken);
    try {
        const response = await axios.post(UrlConfig.UPDATE_DIMENSION_URL,
            JSON.stringify(dimensionParam),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
            }
        )
        console.log("shipmentService.UpdateSize success", response);
        return response;

    } catch (exception) {
       /*  console.log("shipmentService.UpdateSize exception", JSON.stringify(exception));
        return exception; */
        if (!exception.response) {
            console.log(`Update ${dimensionParam.dimensionType} failed`, exception);
            return exception;
        }
        return exception.response;
    }
}



export const EditCustomerAlias = async (props, user) => {
    console.log('shipmentService EditCustomerAlias props', props);
    const authToken = sessionStorage.getItem("AuthToken");
    try {
        const response = await axios.post(UrlConfig.EDIT_CUSTOMERALIAS_URL,
            {
                name: props.name,
                alias: props.alias,
                mapNo: props.mapNo,
                updatedBy: user.username,
            }
            , { headers: { 'Authorization': `Bearer ${authToken}` } })

        console.log('EditCustomerAlias call response', JSON.stringify(response));
        return response;

    } catch (exception) {
        console.log('exception in EditCustomerAlias ', exception)
        return exception;
    }

}


export const DeleteShipment = async (props, user) => {
    const authToken = sessionStorage.getItem("AuthToken");

    console.log('Inside shipmentService DeleteShipment  :authToken, user ', authToken.user);

    try {
        const response = await axios.post(UrlConfig.DELETE_SHIPMENT_URL,
            {
                salesShipmentNo: props.salesShipmentNo,
                deleteReason: props.deleteReason,
                deletedBy: user.username,
                isDeleted: props.isDeleted,
                deletedDate: props.deletedDate
            }
            , { headers: { 'Authorization': `Bearer ${authToken}` } })
        console.log('shipmentService DeleteShipment response' + JSON.stringify(response));
        return response;

    } catch (exception) {
        console.log('exception in shipmentService DeleteShipment ' + exception)
        return exception;
    }

}



export const RollbackShipment = async (props, user) => {
    //console.log('tokens before stringify',localStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('Inside RollbackShipment user.username : ', user.username);
    try {
        const response = await axios.post(UrlConfig.ROLLBACK_SHIPMENT_URL,
            {
                salesShipmentNo: props.salesShipmentNo,
                deleteReason: props.deleteReason,
                deletedBy: user.username,
                isDeleted: props.isDeleted,
                deletedDate: props.deletedDate
            }
            , { headers: { 'Authorization': `Bearer ${authToken}` } })

        console.log('RollbackShipment response', JSON.stringify(response));
        return response;
    } catch (exception) {
        console.log('exception in RollbackShipment ', exception)
        return exception;
    }

}