import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Route, NavLink, HashRouter, BrowserRouter } from 'react-router-dom';
import HomeTopNavBar from './Home/HomeTopNavBar';
import SecondNavbar from './Home/SecondNavbar';
import PrivateRoute from "./RouteComponent/PrivateRoute";
import MyDatePicker from "./MyDatePicker";
import ManifestTab from './ManifestTab/ManifestTab';
import LoginNew from './Login/LoginNew';
import { useSelector } from 'react-redux'
import ShipmentPage from './shipment/ShipmentPage';


const Main = (props) => {
  const isLogedIn = useSelector(state => state.userReducer.loggedIn);

  return (
  
        <div>
          <BrowserRouter>
            {isLogedIn && <Fragment><HomeTopNavBar user={''}></HomeTopNavBar> <SecondNavbar ></SecondNavbar></Fragment>}
            <React.Fragment>
              <PrivateRoute exact path="/" component={ShipmentPage} />
              <Route path="/Login" component={LoginNew} />
              <PrivateRoute path="/Home" component={ShipmentPage} />
              <PrivateRoute path="/ManifestTab" component={ManifestTab} />
              <Route path="/Date" component={MyDatePicker} />
            </React.Fragment>
          </BrowserRouter>
        </div>
    
  )
}

export default Main;