import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CLICK_ACTION } from '../AppConstant/ShippingConstants';
import { Modal, Button, ModalTitle } from 'react-bootstrap';
import 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import * as CssConfig from '../AppConstant/CSSConstant';



import * as TextConfig from '../AppConstant/TextConfig';
const AlertModal = (props) => {

    const fontStyle = {
        fontWeight:'bolder'
    }
    return (
        <Modal centered show={props.show} onHide={() => props.closeModal({ source: CLICK_ACTION.ALERTMODALCLOSE, action: CLICK_ACTION.CLOSE })}  >
            <ModalHeader closeButton>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className={CssConfig.JUSTIFY_CENTER} > <FontAwesomeIcon icon={faCheckCircle} color="green" size="3x"> </FontAwesomeIcon></div>
                        </div>
                    </div>
                </div>

            </ModalHeader>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                        <div className={CssConfig.CENTER_TEXT_BOLD} >  {props.message}</div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div >
                                <button className="btn btn-secondary btn-sm float-right" onClick={() => props.closeModal({ source: 'AlertModalOK', action: 'close' })} >OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default AlertModal;