import React from "react";
import { Route, Redirect } from "react-router-dom";
//import {useAuth} from "../context/auth";//16-DEC-2020

import {useSelector,useDispatch} from 'react-redux'

function PrivateRoute({ component: Component, ...rest }) {
  const user = useSelector(state=>state.userReducer.user);
  //console.log('PrivateRoute user from state.userReducer',user);
  const isLogedIn = useSelector(state=>state.userReducer.loggedIn);
  //console.log('PrivateRoute logedin from state.userReducer',isLogedIn);
  //const { authTokens } = useAuth(); 16-DEC-2020

 //const appToken =  authTokens; 16-DEC-2020

//console.log('PrivateRoute authTokens : ' +  JSON.stringify(appToken) );
  return (
    <Route
      {...rest}
      render={props =>
        isLogedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/Login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;