import React, {useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import {CLICK_ACTION} from '../AppConstant/ShippingConstants'; 
import 'bootstrap/dist/css/bootstrap.css';
import './DeleteShipmentModal.css';

const SubmitPickUpModal = (props) => {

    const [pickUpComment, setTextArea] = useState("");
    const [shipments, setShipments]=useState(props.shipments);
    console.log('SubmitPickUpModal props.shipments ',props.shipments);
    console.log('SubmitPickUpModal CustomerName ',props.shipments[0].customerName);
    useEffect(
        ()=>{
            console.log('SubmitPickUpModal useEffect props.shipments ',props.shipments);
           setTextArea(props.shipments[0].customerName);
        },[]
       );


    const validateComment = () => {


        if (pickUpComment == '') {
            alert('Please provide comments for pick submission');
            return false;
        }
        else {
            props.callBack({ action: CLICK_ACTION.OK, PickUpComment: pickUpComment })
            setTextArea("");
        }

    }
    return (

        <Modal show={props.show} onHide={() => props.callBack({ action: 'cancel' })} centered  >
            <Modal.Header closeButton>
                <Modal.Title>Are you sure to submit this PickUp</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="comment">Comment:</label>
                    <textarea id="deleteComment" value={pickUpComment} onChange={e => { setTextArea(e.target.value); }} className="form-control" rows="3" id="comment"></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="text-left"><button className="btn btn-dark btn-sm" onClick={() =>  props.callBack({ action: 'cancel' })}  >Cancel</button></div>
                        </div>
                        <div className="col">
                            <div className="text-right">
                                {/* <button className="btn btn-danger btn-sm" onClick={() =>{ validateDeleteText() ?  props.onClick({ action: 'delete', shipmentNo:props.shipmentNo}):false }}  >Delete</button> */}
                                <button className="btn btn-danger btn-sm" onClick={() => { validateComment(); }} >Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default SubmitPickUpModal;
