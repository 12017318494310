export const GET_SHIPMENT_URL ="https://api.pallion.com/SalesShipment/shipment";
export const  UPDATE_ADDRESS_URL =    "https://api.pallion.com/SalesShipment/updateaddress";
export const  UPDATE_WEIGHT_URL =    "https://api.pallion.com/SalesShipment/weight";
export const  UPDATE_SIZE_URL =    "https://api.pallion.com/SalesShipment/size";
export const  UPDATE_DIMENSION_URL =    "https://api.pallion.com/SalesShipment/dimension";


export const DELETE_SHIPMENT_URL = 'https://api.pallion.com/SalesShipment/deleteshipment';

export  const  CREATE_AUSPOST_SHIPMENT_URL =    'https://api.pallion.com/AuspostShipment/create';
export  const  CREATE_STARTRACK_SHIPMENT_URL =    'https://api.pallion.com/StarTrackShipment/create';
export  const  LOGIN_URL ="https://api.pallion.com/Users/authenticate";
export  const  AUTH_URL ="https://api.pallion.com/api/Authentication/authenticate";
export const  CREATE_MANIFEST_URL  = "https://api.pallion.com/AuspostShipment/manifest";
export const  STARTRACK_CREATE_MANIFEST_URL = "https://api.pallion.com/StarTrackOrder/create";
export const ORDERSUMMARY_URL = "https://api.pallion.com/AuspostShipment/ordersummary";

export const STARTRACK_ORDERSUMMARY_URL = "https://api.pallion.com/StarTrackOrder/ordersummary"

export  const  UPDATE_POSATGE_URL =    'https://api.pallion.com/SalesShipment/updatepostage';
export  const  SWITCH_COURIER_URL =    'https://api.pallion.com/SalesShipment/switchcourier';

export  const  SUBMIT_PICKUP_URL =    'https://api.pallion.com/SalesShipment/submitpickup';
export  const  UPDATE_LOCATION_URL =    'https://api.pallion.com/SalesShipment/updatelocation';

export  const  MANIFEST_SUMMARY_URL =    'https://api.pallion.com/SalesShipment/manifest';
export  const  MANIFEST_DETAILS_URL =    'https://api.pallion.com/SalesShipment/manifestdetails';
export  const ROLLBACK_SHIPMENT_URL = 'https://api.pallion.com/SalesShipment/rollbackshipment';
export  const EDIT_CUSTOMERALIAS_URL ='https://api.pallion.com/SalesShipment/customeralias';