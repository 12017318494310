import userReducer from './userReducer';
import shipment from './shipmentReducer';
import {combineReducers} from 'redux'
import { defaultTo } from 'lodash';

const rootReducer = combineReducers({
    userReducer,
    shipment
})

export default rootReducer;