import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './dateDiv.css';
import 'react-datepicker/dist/react-datepicker.css'
//import  {CustomDatePicker} from  './CustomControl/CustomDatePicker';

function MyDatePicker() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="dateDiv" >
            <DatePicker selected={startDate} onChange={date => setStartDate(date)} ></DatePicker>
            {/* <CustomDatePicker selected={startDate}></CustomDatePicker> */}
        </div>
    );
}
export default MyDatePicker;

//https://reactdatepicker.com/