const setAll =(shipments)=>{
    //console.log("set allShipments Action SET_ALL");
    return {
        type:"SET_ALL",
        payLoad:shipments
    }
}

const setSelected =(shipments)=>{
    //console.log("set allShipments Action SET_ALL");
    return {
        type:"SET_SELECTED",
        payLoad:shipments
    }
}
const setCount =(count)=>{
    //console.log("set allShipments Action SET_COUNT");
    return {
        type:"SET_COUNT",
        payLoad:count
    }
}
//selected courier tab 
const setCourier =(courier)=>{
    //console.log("setLocation Action courier", courier);
    return {
        type:"SET_COURIER",
        payLoad:courier
    }
}
const setLocation =(location)=>{
    //console.log("setLocation Action location", location);
    return {
        type:"SET_LOCATION",
        payLoad:location
    }
}
const setManifestButton =(flag)=>{
    //console.log("setManifestButton Action flag", flag);
    return {
        type:"SET_MANIFESTBUTTON",
        payLoad:flag
    }
}

const resetAll =()=>{
    return{
        type:"RESET_ALL"
    }
}

const setShipmentGridChkAll =(flag)=>{
    console.log("setShipmentGridChkAll Action flag", flag);
    return {
        type:"SET_SHIPMENTGRID_CHKALL",
        payLoad:flag
    }
}
export default {
    setAll,
    setSelected,
    resetAll,
    setLocation,
    setCourier,
    setCount,
    setManifestButton,
    setShipmentGridChkAll
}