import React from 'react';
import axios from 'axios';
import { format, compareAsc } from 'date-fns';
import * as UrlConfig from '../AppConstant/UrlConfig';



export const GetManifestSummary = async (manifestParam) => {
    console.log('GetManifestSummary', JSON.stringify(manifestParam));
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('inside the UpdatePostage  method');
    
    try {
            const response = await axios.post(UrlConfig.MANIFEST_SUMMARY_URL
                ,JSON.stringify(manifestParam)
                ,{
                    headers:{'Content-Type':'application/json', "Authorization":`Bearer ${authToken}`}
                }
            )
    return response;
    } catch (exception) {
        // console.log('ManifestService GetManifestSummary Exception ', JSON.stringify(exception));
        // return exception;
        if (!exception.response) {
            console.log('GetManifestSummary !exception.response ',JSON.stringify(exception));
            return exception;
        }
        return exception.response;
    }
}

//Getting the shipment details for the amnifestid
export const GetShipmentsByManifestId = async (manifestId) => {
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('inside the GetShipmentsByManifestId  method',manifestId);
    try {
        const response = await axios.post(UrlConfig.MANIFEST_DETAILS_URL ,
            JSON.stringify(manifestId),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
                , params :{ manifestId: manifestId }
            })
        return response;

    } catch (exception) {
        return exception;
    }

}