
import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
const ShipmentGridStatus = (props) => {
    //console.log('ShipmentGridStatus status', props.status);
    if (props.trackingNo !== null) {
        return (<Fragment>
            <span className="badge badge-pill badge-success">{"Tracking#: " + props.trackingNo}</span>
            <div><span className="badge badge-pill badge-success">{"Label:"}</span> <a href={props.labelUrl} target="_blank"> <FontAwesomeIcon icon={faFilePdf} color="red"  ></FontAwesomeIcon> </a> </div>
            <div><span className="badge badge-pill badge-danger">{"Roll Back:"}</span><FontAwesomeIcon onClick={() => props.callback({ shipmentNo: props.salesShipmentNum })} icon={faEraser} color="red"    ></FontAwesomeIcon></div>
        </Fragment>
        )
    }
    else if (props.status !== null) {
        return (<span className="badge badge-pill badge-danger block-badge">{props.status}</span>)
    }
    else {
        return (<div></div>)
    }

}
export default ShipmentGridStatus;


