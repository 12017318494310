

export const GetManifestAlertDetails = (shipmentOrders, selectedShipmentIds) => {
    var totalCount = shipmentOrders.length;
    console.log('GetManifestAlertDetails shipmentOrders : ', shipmentOrders);
    console.log('GetManifestAlertDetails totalCount : ', totalCount);

    var validShipments = shipmentOrders.filter(order =>
        order.trackingNumber !== null)
        var unProcessed = totalCount - validShipments.length;

        console.log('GetManifestAlertDetails unProcessed : ', unProcessed);
        console.log('GetManifestAlertDetails validShipments : ', validShipments);
    var ManifestDetails = {
        totalCount: totalCount,
        validShipments: validShipments.length,
        selectedShipments:selectedShipmentIds.length,
        unProcessed:unProcessed
    };

    return ManifestDetails;
}