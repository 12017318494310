
import React, {Fragment, useState, useEffect } from 'react'
import * as shippingConst from '../AppConstant/ShippingConstants';
import {CLICK_ACTION, DIMENSION} from '../AppConstant/ShippingConstants'; 
import * as TextConfig from '../AppConstant/TextConfig'; 
import {COMPONENT} from '../AppConstant/ShippingConstants'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import DeleteShipmentModal from '../Home/DeleteShipmentModal';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';
import * as LocationService from './LocationService';
import * as shipmentService from './shipmentService';
import Checkbox from '../CustomControl/Checkbox';
import ProcessingModal from '../CustomControl/ProcessingModal';
import { faFilter, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AlertModal from '../CustomControl/AlertModal';
import {useSelector, useDispatch} from 'react-redux'
import CustomerAlias from '../Customer/CustomerAlias';
import * as shipmentFilter from './shipmentFilter';
import * as shipmentHelper from './shipmentHelper'
import allActions from '../redux/actions'
import LocationUpdateDropdown from './LocationUpdateDropdown';
import AddressView from './AddressView';
import AddressModal from './AddressModal';
import ShipmentGridStatus from './ShipmentGridStatus';
import './ShipmentGrid.css';
import EditPencil from '../CustomControl/EditPencil';
import DimensionModal from './DimensionModal';

import ErrorModal from '../CustomControl/ErrorModal';

import * as sessionService from '../Session/StorageService'
const ShipmentGrid = (shipmentGridProps)=>{
   const dispatch = useDispatch();
   const selectAllFromStore = useSelector(state=>state.shipment.shipmentGridChkAll);
   
   console.log('ShipmentGrid  selectAllFromStore',selectAllFromStore);

   const userFromStore = useSelector(state=>state.userReducer);
   const allShipmentFromStore = useSelector(state=>state.shipment.allShipments);
   const selectedShipmentsFromStore = useSelector(state=>state.shipment.selected);
   const courierFromStore = useSelector(state=>state.shipment.courier);
   //console.log('ShipmentGrid  selectedShipments',selectedShipmentsFromStore);
   const [isLoading, setIsLoading] = useState(false);
   const [processingMessage, setProcessingMesage]= useState("Fetching shipments........");
   
   const[showCommonProgressModal,setCommonProgressModal]= useState(false);
   const[commonProgressProps,setCommonProgressProps]= useState({});
   const[showAlertModal, setShowAlertModal]=useState(false);
   const[alertModalProps, setAlertModalProps]=useState({});
   const[showCustomerAlias,setShowCustomerAlias]=useState(false);
   const[customerAliasData, setCustomerAliasData] = useState({});
   /* Address Edit Modal   */
   const[showAddressModal,setShowAddressModal] = useState(false);
   const[addressModalContent,setaddressModalContent] = useState({});
   const[showDeleteModal, setDeleteModal] = useState(false);
   const[shipmentToDelete, setShipmentToDelete] = useState('');
   const[checkBoxAll, setCheckBoxAll] = useState(false);

   /* Dimensions(size & weight)  Edit Modal    */
   const[showDimensionModal,setDimensionModal] = useState(false);
   const[dimension,setDimension] = useState({});
   const [showError,setError]= useState(false);
   const[errorMessage, setErrorMessage]=useState('');


   const closeCancelModal =(props)=>{
      console.log('inside CLICK_ACTION.ERRORMODALCLOSE',props);
      if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE)
      {
        setErrorMessage('');setError(false);
        //shipmentGridProps.callBack({ source:'shipmentGrid' ,axRefresh : false, startDate : new Date(), endDate : new Date(), courierFromStore : courierFromStore});
      }
    }


   //select all checkbox 
   const   checkBoxSelectAllHandler  =(flag)=>{
      var status = flag.target.checked;
      console.log('ShipmentGrid  checkBoxSelectAllHandler flag',status);
      dispatch(allActions.shipmentActions.setShipmentGridChkAll(status));
      var  updatedShipments = shipmentFilter.ToggleSelectAll(selectedShipmentsFromStore,status);
      console.log('ShipmentGrid  checkBoxSelectAllHandler updatedShipments',updatedShipments);
      //dispatch(allActions.shipmentActions.setSelected([]));
      dispatch(allActions.shipmentActions.setSelected(updatedShipments));

   }
   
   //CallBack from the LocationDropdown once the new address is selected
   const  locationUpdateCallBack =(props)=>{
      console.log('ShipmentGrid LocationUpdateDropdown callback event',props.newLocation);
      let newAddress= shippingConst.FROM_ADDRESS[props.newLocation];
      let salesShipmentNum = props.salesShipmentNum
      console.log('ShipmentGrid LocationUpdateDropdown callback newAddress , salesShipmentNum',newAddress, salesShipmentNum);
      var tempOrders =  LocationService.GetUpdatedOrder(selectedShipmentsFromStore,newAddress,salesShipmentNum);
      console.log('FromAddressChangeHandler updatedOrders', tempOrders);
      var toUpdateObj = selectedShipmentsFromStore.find(obj => {
         return obj.salesShipmentNum === salesShipmentNum
      });
      var  updateShipmentArray = LocationService.GetUpdateArray(tempOrders,salesShipmentNum);

      var popUpMsg = { title:`Updating  Location  for ShipmentNumber : ${salesShipmentNum}`
                     ,message:`Updating the Location details for ShipmentNumber : ${salesShipmentNum}`}
         setCommonProgressProps(popUpMsg);
         setCommonProgressModal(true);
       LocationService.UpdateLocation(updateShipmentArray).then((response)=>{
         setCommonProgressModal(false);
         if (response.status===200)
         {
           setAlertModalProps({message:"Location updated successfully"});
           setShowAlertModal(true);
           shipmentGridProps.callBack({ source:'shipmentGrid' ,axRefresh : false, startDate : new Date(), endDate : new Date(), courierFromStore : courierFromStore});
           
         }
         else if (response.response.status===401)
         {
           dispatch(allActions.userActions.logOut());
           sessionService.SetSessionExpiry();
         }
         else{
            
            setAlertModalProps({message:"Update didn't go through, please try again"});
            setShowAlertModal(true);
         }

      })

   }


   

   //common callback from modals for cancel and close[X] button click
   const closeCancelModel =(props)=>{
      console.log('ShipmentGrid closeCancelModel props',props);
      if (props.action === 'close' &&  (props.source === 'AlertModalClose'  || props.source === 'AlertModalOK'))
      {
         setShowAlertModal(false);
      }
   }

   const  onCheckBoxChangeHandler =(salesShipmentNum)=>{
      console.log('ShipmentGrid  onCheckBoxChangeHandler salesShipmentNum',salesShipmentNum);
      var  updatedShipments = shipmentFilter.SetSelectedFlag(selectedShipmentsFromStore,salesShipmentNum);
      console.log('ShipmentGrid  onCheckBoxChangeHandler updatedShipments',updatedShipments);
      dispatch(allActions.shipmentActions.setSelected(updatedShipments));
   }
  
//call back from address container on edit button click (AddressView)
const addressEditCallBack =(props)=>{
      console.log('ShipmentGrid addressEditCallBack props',props);
      console.log('ShipmentGrid JSON.parse(props)',  JSON.parse( JSON.stringify(props)));
      setaddressModalContent({message:'Update Address',address:JSON.parse( JSON.stringify(props))})
      setShowAddressModal(true);

}

//call back from address Edit modal (AddressModal) on click or cancel/edit 
const addressModalCallBack =(props)=>{
   console.log('ShipmentGrid addressModalCallBack  Edit props',props);
   console.log('ShipmentGrid CLICK_ACTION.CANCEL',CLICK_ACTION.CANCEL);
   setShowAddressModal(false);
/*    setShowAddressModal(true);
   setaddressModalContent({}); */
   if (props.action === CLICK_ACTION.UPDATE) {
      setProcessingMesage("Please wait ....Updating address");
      setIsLoading(true);
     AddressUpdateHandler(props.address);
 /*     setShowAddressModal(true);
     setaddressModalContent({}); */
   }else if  (props.action === CLICK_ACTION.CANCEL){
      setShowAddressModal(false);
   }

}

const AddressUpdateHandler = (address) => {
      console.log('address:' + JSON.stringify(address));
      var shipmentObj = shipmentHelper.GetUpdatedShipment(address, selectedShipmentsFromStore);
      console.log('AddressUpdateHandler showAddressModal',showAddressModal)
   shipmentService.UpdateAddress(shipmentObj,userFromStore.user).then((response) => {
      console.log('AddressUpdateHandler.UpdateAddress response json' , JSON.stringify(response));
      console.log('AddressUpdateHandler.UpdateAddress response response.response' , response.response);
      if (response.status === 204) {
         setProcessingMesage("Please wait ....Updating address");
         setIsLoading(false);
         console.log('AddressUpdateHandler 204');
         shipmentGridProps.callBack({ source:'shipmentGrid' ,axRefresh : false, startDate : new Date(), endDate : new Date(), courierFromStore : courierFromStore});
         setAlertModalProps({message: "Update Address Completed"});
         setShowAlertModal(true);
      }
      else if (response.response.status===401)
      {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
     else if (response.response.status === 422){
      shipmentPageCallBack();
      setIsLoading(false);
      console.log('UpdateAddress inside 422 response.response ',response.response);
        var errorMessage= response.response.data['shipmentPriceError'];
        setErrorMessage(`Update Address falied, ${errorMessage}`)
         setError(true);
      }
      else{
         
         console.log('AddressUpdateHandler.UpdateAddress else condition ',  JSON.stringify(response));
      
      }
   })

}


   // pencil icon against customer name is clicked (update customer alias data)
   const CustomerAliasClickHandler = (props) => {
      console.log('shipmentGrid CustomerAliasClickHandler props ', props);
      var customerInfo = shipmentHelper.GetCustomerInfo(props);
      setCustomerAliasData(customerInfo);
      setShowCustomerAlias(true);
   }
   const CustomerAliasCallBack = (props) => {
      console.log('CustomerAliasCallBack props' + JSON.stringify(props));
      console.log('CustomerAliasCallBack !props.action === CLICK_ACTION.CANCEL' ,!(props.action === CLICK_ACTION.CANCEL));
      setShowCustomerAlias(false);
      if (!(props.action === CLICK_ACTION.CANCEL)) {
         shipmentService.EditCustomerAlias(props.address, userFromStore.user).then((response) => {
            console.log('CustomerAliasCallBack status' + JSON.stringify(response));
            if (response.status === 200) {
               console.log('isnide status = 200');
              shipmentPageCallBack ();
             setAlertModalProps({message:"CustomerAlias updated successfully"});
               setShowAlertModal(true);
               console.log('CustomerAliasCallBack isLoading' ,isLoading);
            }
            else if (response.status===401)
            {
              dispatch(allActions.userActions.logOut());
              sessionService.SetSessionExpiry();
            }
         });
         console.log('CustomerAliasCallBack props update' + JSON.stringify(props));
      }

   }

 const shipmentPageCallBack =()=>{
      shipmentGridProps.callBack({ source:COMPONENT.ShipmentGrid
                                  ,axRefresh : false
                                  , startDate : new Date()
                                  , endDate : new Date()
                                  , courierFromStore : courierFromStore});
      }
    
      const  DeleteHandler = (props)=> {
         console.log('setDeleteModal ' + props);
        setShipmentToDelete(props.shipmentNo);
        setDeleteModal(props.flag);
      }

   const deleteModalCallBack = (props) => {
      console.log(' deleteModalCallBack props',props);
      setDeleteModal(false);
      if (props.action === CLICK_ACTION.DELETE) {
         var deleteShipmentObj = shipmentHelper.GetShipmentToDelete(props);
         console.log(' deleteModalCallBack deleteShipmentObj',deleteShipmentObj);
         shipmentService.DeleteShipment(deleteShipmentObj, userFromStore.user).then((response) => {
            console.log('Delete status' + JSON.stringify(response));
            if (response.status === 200) {
               console.log('inside deleteModalCallBack status = 200');
               var  updatedAllShipments = shipmentHelper.RemoveShipment(allShipmentFromStore,props.shipmentNo);
               var finalOrders = shipmentHelper.RemoveShipment(selectedShipmentsFromStore,props.shipmentNo);
               dispatch(allActions.shipmentActions.setAll(updatedAllShipments));
               dispatch(allActions.shipmentActions.setSelected(finalOrders));//reset the selectedSHipemtns with the new array after deletion 
               var countByCourier = shipmentFilter.GetCountByCourier(updatedAllShipments);
               dispatch(allActions.shipmentActions.setCount(countByCourier));
               setAlertModalProps({message:"Shipment deleted successfully"});
               setShowAlertModal(true);
            }
            else if (response.status===401)
            {
              dispatch(allActions.userActions.logOut());
              sessionService.SetSessionExpiry();
            }
         });
      }
      
      console.log('this.state.shipmentOrders.length' ,selectedShipmentsFromStore.length);

   }

   
   const ShipmentRollback = (props) => {
      console.log('ShipmentRollback props' + JSON.stringify(props));
       var rollBackParam =   shipmentHelper.GetRollBackData(props)
      console.log('ShipmentRollback rollBackParam  ', rollBackParam);
      shipmentService.RollbackShipment(rollBackParam,userFromStore).then((response) => {
         console.log('ShipmentRollback status' + JSON.stringify(response));
         if (response.status === 200) {
            console.log('isnide status = 200');
            shipmentPageCallBack ();
            setAlertModalProps({message:"Shipment roll back success" });
            setShowAlertModal(true);
         }
         else if (response.status===401)
         {
           dispatch(allActions.userActions.logOut());
           sessionService.SetSessionExpiry();
         }
      });
   }

//handler for edit weight and edit size
const editDimensions =  (props) =>{
   console.log('editDimensions props' + JSON.stringify(props));
   setDimension(props);
   setDimensionModal(true);
}
   const dimensionModalCallback = (props) => {
      setDimensionModal(false);
      var dimensionParam =  shipmentHelper.GetUpdatedDimension(props.dimension,selectedShipmentsFromStore, userFromStore.user)
      if (props.action === CLICK_ACTION.UPDATE && props.dimension.type === DIMENSION.WEIGHT ) {
          var status = shipmentService.UpdateDimension(dimensionParam).then((response)=>{
             if (response.status === 200)
             {
               shipmentPageCallBack ();
               displayConfirmation("Weight updated successfully",true);             
            }
            else if (response.status===401)
            {
              dispatch(allActions.userActions.logOut());
              sessionService.SetSessionExpiry();
            }
             else{
               shipmentPageCallBack ();
               setErrorMessage("Update didn't go through, please check the status");
               setError(true);
               //displayConfirmation("Update didn't go through, please try again",true);
             }
             

          })
      }
      else if (props.action === CLICK_ACTION.UPDATE && props.dimension.type === DIMENSION.SIZE )
      {
         
         var status = shipmentService.UpdateDimension(dimensionParam).then((response)=>{
            console.log('shipmentService.UpdateSize response',response);
            if (response.status === 200)
            {
               shipmentPageCallBack ();
              displayConfirmation("Size updated successfully",true);             
            }
            else  if (response.status===401)
            {
              dispatch(allActions.userActions.logOut());
              sessionService.SetSessionExpiry();
            }
            else{
               shipmentPageCallBack ();
               setErrorMessage("Update didn't go through, please check the status");
               setError(true);
             // displayConfirmation("Update didn't go through, please try again",true);
            }

         })
      }
   }


   // common message to displayConfirmation or alert user
const displayConfirmation =  (msg, flag)=>{
   setAlertModalProps({message:msg});
   setShowAlertModal(flag);
}


   const  renderTableHeader=()=> {
        return (
           <Fragment>
              <th>#</th>
              <th><Checkbox  id={"chkSelectAll"}  isSelected= {selectAllFromStore}     onCheckBoxChange={checkBoxSelectAllHandler}  ></Checkbox></th>
              <th>Account</th>
              <th>Invoice/Shipment#</th>
              <th>Courier Name </th>
              <th>
               <span>Location  &nbsp;  <FontAwesomeIcon    icon={faFilter}></FontAwesomeIcon></span>
              </th>
              <th>Name</th>
              <th>Address</th>
              <th>Size(L,W,H)</th>
              <th>Cost($)</th>
              <th>Weight(g)</th>
              <th>Status</th>
              <th></th>
           </Fragment>)
     }
  
  
     const renderTableData =()=> {
        //console.log('ShipmentGrid  renderTableData selectedShipments',selectedShipmentsFromStore);

        console.log('ShipmentGrid  renderTableData  isArray  shipmentOrders',Array.isArray(selectedShipmentsFromStore));
        return selectedShipmentsFromStore.map((order, index) => {
           const { customerAccount, customerName, toCity, toPostcode, toState, toStreet, boxSizeX, boxSizeY, boxSizeZ, salesShipmentNum, totalValue, shipmentPriceError, totalweight, isSelected, invoiceNumber,trackingNumber, labelUrl, fromStreet, postageCode, customerNameAlias } = order //destructuring
           var isLocationDisabled = trackingNumber!==null? true:false;
           var formattedAliasName = customerNameAlias!==null?`[${customerNameAlias}]`:'';
           return (
              <tr scope="row" key={salesShipmentNum} >
                 <td scope="col">{index+1}</td>
                 <td scope="col">{isSelected}<Checkbox isSelected={isSelected} id={"chk"+salesShipmentNum} onCheckBoxChange={()=>onCheckBoxChangeHandler(salesShipmentNum)}   ></Checkbox></td>
                 <td scope="col">{customerAccount}</td>
                 <td scope="col">{invoiceNumber}<br></br>{salesShipmentNum}</td>
                 <td scope="col">{postageCode}</td>
                 <td><LocationUpdateDropdown   location ={fromStreet}  disabled={isLocationDisabled}   shipmentGridCallBack={locationUpdateCallBack}  trackingNumber={trackingNumber}     salesShipmentNum={salesShipmentNum}></LocationUpdateDropdown></td>
                 <td scope="col">{customerName} {formattedAliasName} &nbsp; 
                 <FontAwesomeIcon  id="editName" icon={faPencilAlt} size="1x"  title="Edit Alias" color="red" 
                 onClick={()=>{CustomerAliasClickHandler({name:customerName,alias:customerNameAlias,mapNo:customerAccount,salesShipmentNum:salesShipmentNum})}}></FontAwesomeIcon>
                 </td>
                 <td scope="col"> 
                     <AddressView   shipmentGridCallBack={addressEditCallBack}    salesShipmentNum= {salesShipmentNum} 
                        toStreet={toStreet} toCity={toCity} toState={toState} toPostcode={toPostcode} 
                        shipmentPriceError={shipmentPriceError} 
                     />
                    </td>
                 <td>
                    {"(" + boxSizeX + ", " + boxSizeY + "," + boxSizeZ + ")"}
                    <FontAwesomeIcon  id="editName" icon={faPencilAlt} size="1x"  title="Edit Alias" color="red" 
                 onClick={()=>{editDimensions({type:"size",length:boxSizeX,width:boxSizeY, height:boxSizeZ, salesShipmentNum:salesShipmentNum})}}></FontAwesomeIcon>
                 </td>
                 <td scope="col">{totalValue}</td>
                 <td scope="col" >{totalweight} <FontAwesomeIcon  id="editName" icon={faPencilAlt} size="1x"  title="Edit Alias" color="red" 
                 onClick={()=>{editDimensions({type:"weight",weight:totalweight,salesShipmentNum:salesShipmentNum})}}></FontAwesomeIcon></td>
                 <td scope="col" > 
                  <ShipmentGridStatus salesShipmentNum={salesShipmentNum}  
                 status={shipmentPriceError}   trackingNo={trackingNumber} labelUrl={labelUrl}
                 callback={ShipmentRollback}
                 ></ShipmentGridStatus>
                  </td>
                 <td scope="col" className="archive" >
                    <FontAwesomeIcon icon={faTrashAlt} color="black"  id="faTrashAlt"  
                    onClick={()=>DeleteHandler({flag:true, shipmentNo:salesShipmentNum})}   />
                     <DeleteShipmentModal show={showDeleteModal}  shipmentNo = {shipmentToDelete}
                     onClick={deleteModalCallBack}   onHide={deleteModalCallBack}
                     >
                     </DeleteShipmentModal>
                 </td>
              </tr>
           )
        })
     }
  

        return (
          
          <div>
           
              {/* <div style={{height: '600px',overflow: 'scroll'}}> */}
              {!isLoading ?
                       (
                 <div className="table-responsive gridHolderShipmentGrid">
                 
                          <table id='ShipmentGrid' className='table table-striped ShipmentGrid'>
                             <thead>
                             <tr >{renderTableHeader()}</tr>
                             </thead>
                             <tbody    >
                           {/*   <CommonProgressModal title={commonProgressProps.title}  show={showCommonProgressModal} >
                             </CommonProgressModal> */}
                             <AlertModal show={showAlertModal} message={alertModalProps.message} closeModal={closeCancelModel}   ></AlertModal>
                             
                             { showAddressModal && <AddressModal show ={showAddressModal}   
                              address={addressModalContent.address} callBack={addressModalCallBack} 
                              message={addressModalContent.address.message}></AddressModal> }
                             {showCustomerAlias &&<CustomerAlias  
                             callBack={CustomerAliasCallBack}  
                             address={customerAliasData}    show={showCustomerAlias}  >
                             </CustomerAlias>}

                             { showDimensionModal &&  <DimensionModal  
                              show={showDimensionModal}   
                               dimension={dimension} 

                               title = {TextConfig.EDIT_SIZE_TITLE}
                               callBack={dimensionModalCallback}
                               ></DimensionModal>} 
                               { showError && <ErrorModal show={showError}  message={errorMessage} closeModal={closeCancelModal}   ></ErrorModal>}
                              {renderTableData()}
                             </tbody>
                          </table> 
                      
                 </div>
                  ) : (
                    <Fragment>
                       <ProcessingModal show={isLoading} title={processingMessage}  ></ProcessingModal>
                    </Fragment>
                 )
              }
              </div>
        )
}  
  

export default ShipmentGrid;