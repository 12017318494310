import React,{useState} from 'react';

import { Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

const AddressModal = (props) => {
    console.log('Inside AddressModal props',props);
     var address =  props.address;
    console.log('Inside AddressModal address',address);
   
   
       const [addressState, setAddressState] = useState(
        {
            street : address.toStreet
            ,place : address.toCity
            , state: address.toState
            , postCode: address.toPostCode
            ,salesShipmentNum :address.salesShipmentNum
        }); 

        console.log('AddressModal After Init addressState ',addressState);
      
        const changeHandler =(props)=> {
            console.log('AddressModal Inside changeHandler props.target.value',props.target.value);
            console.log('AddressModal Inside changeHandler props.target.name',props.target.name);
            const newValue = props.target.value;
            setAddressState({
                ...addressState,
                [props.target.name]: newValue
            });
        }
        
        const UpdateButtonHandler = () => {

            if (addressState.state === '' ||addressState.place==='' || addressState.postCode ===''||addressState.street ==='' ) {
                alert('Please fill all the fields');
                return false;
            }
            else {
                props.callBack({ action: 'update', address: addressState })
                
                setAddressState({ street : ''
                    ,place : ''
                    , state: ''
                    , postCode: ''
                    ,salesShipmentNum :''});
            }
    
        }
   
    return (
        <Modal  centered   show={props.show}  onHide={()=>{ props.callBack({ action: 'cancel'})}} >
            <Modal.Header closeButton>
                <Modal.Title>{props.message} {props.postageName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="container">
                <div className="row">
                        <div className="col">
                            <div className="col">
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Street</span>
                                    </div>
                                    <input type="text" name="street" value={addressState.street} onChange={changeHandler} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col">
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">Place</span>
                                    </div>
                             
                                    <input type="text" name="place" value={addressState.place} onChange={changeHandler} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                                    {/* <input type="text" name="place" value={addressState.place} onChange={changeHandler} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col">
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">State</span>
                                    </div>
                                    <input type="text"  name="state" value={addressState.state} onChange={changeHandler} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="col">
                                <div className="input-group input-group-sm mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-sm">PostCode</span>
                                    </div>
                                    <input type="text"  name="postCode" value={addressState.postCode} className="form-control" onChange={changeHandler} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                
            <div className="container">
            <div className="row">
                    <div className="col">
                            <div className="text-left"><button className="btn btn-dark btn-sm" onClick={()=>{ props.callBack({ action: 'cancel'})}} >Cancel</button></div>
                        </div>
                        
                        <div className="col">
                            <div >
                            <button className="btn btn-secondary btn-sm float-right" onClick={UpdateButtonHandler} >Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default AddressModal;