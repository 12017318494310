import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const AddressContainer = (props) => {
   const [addressState, setAddressState] = useState(
      {
         toStreet: props.toStreet
         , toCity: props.toCity
         , toState: props.toState
         , toPostCode: props.toPostcode
         , salesShipmentNum: props.salesShipmentNum
      });
   var validationError = '';
   //const[validationError,setValidationError] =useState('');
   const AddressValidation = (props) => {
      validationError = '';

      if (props.shipmentPriceError != null) {
         //console.log('AddressValidation props.shipmentPriceError',props.shipmentPriceError);
         //setValidationError("Fix the auspost error in status")
         validationError = "Fix the auspost error in status";
         return "Fix the auspost error in status"
      }
      else if (props.toStreet == '') {
         //setValidationError("Street is empty")
         validationError = "Street is empty";
         return "Street is empty"

      }
      else if (props.toCity == '') {
         //setValidationError("City is empty")
         validationError = "City is empty";
         return "City is empty"
      }
      else if (props.toState == '') {
         //setValidationError("State is empty")
         validationError = "State is empty";
         return "State is empty"
      }
      else if (props.toPostcode == '') {
         //setValidationError("Postcode is empty")
         validationError = "Postcode is empty";
         return "Postcode is empty "
      }

      else {
         return '';
      }

   }
   //console.log('AddressDiv addressState :  ',addressState);
   const editClickCall = () => {
      props.shipmentGridCallBack(addressState);
   }

   if (AddressValidation(props) !== '') {
      return <div>
         <div>{props.toStreet + ', ' + props.toCity + ', ' + props.toState + ', ' + props.toPostcode} </div>
         <span className="badge badge-pill badge-danger">{`${validationError}, this is mandatory for creating shipments for auspost, Toll, & TNT`}</span>
         <FontAwesomeIcon onClick={editClickCall} id="editName" icon={faPencilAlt} size="1x" title="Edit Address" color="red" ></FontAwesomeIcon>
      </div>
   }
   else {
      return <div>{props.toStreet + ', ' + props.toCity + ', ' + props.toState + ', ' + props.toPostcode}
         <FontAwesomeIcon onClick={editClickCall} id="editName" icon={faPencilAlt} size="1x" title="Edit Address" color="red" ></FontAwesomeIcon>
      </div>
   }

}
export default AddressContainer;





