const userReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.payLoad,
                loggedIn: true,
            }

        case "LOG_OUT":
            return {
                ...state,
                user: {},
                loggedIn: false,
            }
        default:
            return state;
    }

}
export default userReducer;