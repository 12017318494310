import { Fragment } from "react";
import React, {useState}from 'react';
import Checkbox from  '../CustomControl/Checkbox';
import ProcessingModal from '../CustomControl/ProcessingModal';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';
import './ManifestGrid.css'
import * as ManifestService from './ManifestService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {faFilePdf}   from '@fortawesome/free-regular-svg-icons';
import * as OrderSummaryService from  '../Home/OrderSummaryService';

import {useSelector} from 'react-redux'

const ManifestGrid =(props)=>
{

const courierFromStore = useSelector(state=>state.shipment.courier);
 //console.log('props.manifestArr',props.manifestArr);

 console.log('entered  ManifestGrid');
 const[ isLoading, setIsLoading] = useState(false);
 const[spinner,setSpinner]=useState(props.spinner);
 console.log('ManifestGrid props.spinner',props.spinner);
 const CheckBoxChangeHandler =(manifest)=>{
console.log('CheckBoxChangeHandler manifest',manifest);
 }

/*  const viewDetailsHandler =(manifestId)=>{
   console.log('viewDetailsHandler manifestId',manifestId);
   setSpinner(true)
   props.CallBack(manifestId);
 }
  */

 //const locationFromStore = useSelector(state=>state.shipment.location);
const DownLoadManifest =(manifestId,location, courierFromStore)=>{
   OrderSummaryService.DownLoadPdf(manifestId,location,courierFromStore).then(
      response => {
          if (response.status === 200) {
              console.log('downLoadHandler 200 response',response);
              console.log('downLoadHandler 200 response.data',response.data);
              var blob = new Blob([response.data], {type: 'application/pdf'});
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.target = "_blank";
              //link.setAttribute('download', `${props.orderNo}` + '.pdf');
              //document.body.appendChild(link);
              link.click();
          }

      })

}


 const viewDetailsHandler =(manifestId)=>{
   setSpinner(true)
   console.log('viewDetailsHandler manifestId',manifestId);
   console.log('ManifestTab ViewDetailsCallback manifestId ', manifestId);
    ManifestService.GetShipmentsByManifestId(manifestId).then((response) => {
      if (response.status === 200) {
        //console.log('GetShipmentsByManifestId ViewDetailsCallback success summary length', response.data.length);
       // setShipmentSummary(response.data);
       setSpinner(false)
       props.CallBack(response.data);
       
        console.log('viewDetailsHandler  GetShipmentsByManifestId success', JSON.stringify(response));
      } else {
        console.log('viewDetailsHandler  GetShipmentsByManifestId else', JSON.stringify(response));
      }
    })
  
  
 }
    const renderTableHeader =()=> {
    return (
       <Fragment>
          {/* <th><Checkbox  ></Checkbox></th> */}
          <th>#</th>
          <th>Created Date</th>
          <th>Created By</th>
          <th>Manifest #</th>
          <th>Courier</th>
          <th>Total Cost</th>
          <th>View</th>
          <th>Download</th>
       </Fragment>)
       }

      
 const renderTableData =()=> {
    return props.manifestArr.map((manifestItem, index) => {
       const { createdDate,createdBy, manifestid, view, download,totalCost, courier,location,pallionEntity } = manifestItem //destructuring
       //console.log('props.manifestArr',props.manifestItem);
       return (
          <tr scope="row" key={manifestid} >
            {/*  <td scope="col"><Checkbox  onCheckBoxChange={()=>CheckBoxChangeHandler(manifestid)}  ></Checkbox></td> */}
            <td scope="col">{ index+1}</td>
             <td scope="col">{createdDate}</td>
             <td scope="col">{createdBy}</td>
             <td scope="col">{manifestid}</td>
             <td scope="col">{courier}</td>
             <td scope="col">{totalCost}</td>
             <td scope="col">
               <button type="button" className="btn btn-outline-dark btn-sm viewButtonFont" onClick={()=>{viewDetailsHandler(manifestid,location)}} >
                  {spinner ? (
                    <Fragment>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Fetching....
                        </Fragment>) : ("View Shipments")}
                        </button>
                </td>
             <td scope="col"><FontAwesomeIcon icon={faFilePdf} color="red" title="Open PDF" id="ManifestPdf" size="2x" onClick={()=>{DownLoadManifest(manifestid,location,courier)}}  ></FontAwesomeIcon></td>
          </tr>
       )
    })
 }



 return (
       <div>
          {!isLoading ?
                   (
             <div className="table-responsive gridHolderManifestGrid">
                      <table id="ManifestGrid" className="table table-striped ManifestGrid">
                      
                         <thead>
                         <tr >{renderTableHeader()}</tr>
                         </thead>
                         <tbody    >
                            {renderTableData()}
                         </tbody>
                      </table>
                  
             </div>
              ) : (
             /*     <Fragment>
                <p>Loading... Please wait</p>
                <BtProgressBar  variant="success" now={100}  label={"Fetching shipments......."}  ></BtProgressBar>
                </Fragment> */
                <Fragment>
                  {/*  <ProcessingModal show={isLoading} title="Fetching shipments........"  ></ProcessingModal> */}
                </Fragment>
             )
          }
          </div>
    )
}
export default ManifestGrid;