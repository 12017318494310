import React , {useEffect, useState} from 'react'
import * as shippingConst from '../AppConstant/ShippingConstants'
import {COURIER} from '../AppConstant/ShippingConstants'
import * as shipmentFilter from './shipmentFilter';
import  {useSelector,useDispatch} from 'react-redux'
import allActions from '../redux/actions'
import {Tab,Tabs} from 'react-bootstrap'
const CourierTabBar = (props) => {
    const dispatch = useDispatch();
    const allShipmentFromStore = useSelector(state=>state.shipment.allShipments);
    const locationFromStore = useSelector(state=>state.shipment.location);
    const courierFromStore = useSelector(state=>state.shipment.courier);
    const courierCount = useSelector(state=>state.shipment.count);
    const selectedShipments = useSelector(state=>state.shipment.selected);
    var activeTabCss = "nav-item nav-link active";
    var tabCss = "nav-item nav-link";
    //console.log('CourierTabBar courierCount',courierCount);

 
    // currently courier props is passed for fitlering recrods,
    // because courierFromStore is having the previous value  TODO:Nevin:Need to check 
    const handleTabClick = (courier) => {
        dispatch(allActions.shipmentActions.setCourier(courier));
        console.log('handleTabClick tab selected ' , courier);
        console.log('handleTabClick tab selected, courierFromStore, locationFromStore  ',  courier,courierFromStore, locationFromStore);
        var selectedShipments = shipmentFilter.FilterShipments(allShipmentFromStore,courier,locationFromStore);
        console.log('handleTabClick selectedShipments ',selectedShipments);
        dispatch(allActions.shipmentActions.setSelected(selectedShipments));
        props.toggleManifestCallBack(selectedShipments,'CourierTabBar');
    }
   
    


    return (
      <nav>
            <div className="nav nav-tabs" id="courierTab" role="tablist">
                 <a className={courierFromStore===COURIER.AUSPOST.name?activeTabCss:tabCss} id="nav-auspost-tab" data-toggle="tab" href="#nav-home" role="tab"  aria-selected="true" onClick={() => handleTabClick(COURIER.AUSPOST.name)}   >Auspost &nbsp;<span className="badge badge-pill badge-dark">{courierCount && courierCount.auspost}</span></a> 
               {/*   <a className={courierFromStore===COURIER.RONW.name?activeTabCss:tabCss} id="nav-ronw-tab" data-toggle="tab" href="#nav-ronw" role="tab"  aria-selected="false" onClick={() => handleTabClick(COURIER.RONW.name)}   >RonW &nbsp;<span className="badge badge-pill badge-dark">{courierCount && courierCount.ronw}</span>   </a> */}
                <a className={courierFromStore===COURIER.PICKUP.name?activeTabCss:tabCss} id="nav-pickup-tab" data-toggle="tab" href="#nav-pickup" role="tab"  aria-selected="false" onClick={() => handleTabClick(COURIER.PICKUP.name)}   >Pickup &nbsp;<span className="badge badge-pill badge-dark">{courierCount && courierCount.pickUp}</span>   </a>
                {/* <a className={courierFromStore===COURIER.OTHERS.name?activeTabCss:tabCss} id="nav-others-tab" data-toggle="tab" href="#nav-others" role="tab"  aria-selected="false" onClick={() => handleTabClick(COURIER.OTHERS.name)}   >Others &nbsp;<span className="badge badge-pill badge-dark">{0}</span>   </a> */}
                <a className={courierFromStore===COURIER.STARTRACK.name?activeTabCss:tabCss} id="nav-pickup-tab" data-toggle="tab" href="#nav-pickup" role="tab"  aria-selected="false" onClick={() => handleTabClick(COURIER.STARTRACK.name)}   >StarTrack &nbsp;<span className="badge badge-pill badge-dark">{courierCount && courierCount.starTrack}</span>   </a>
            </div>
        </nav> 
 
    )
}

export default CourierTabBar;