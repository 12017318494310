import * as shippingConst from '../AppConstant/ShippingConstants'

export const isValidLocation = (location) =>{
    if (shippingConst.FROM_ADDRESS["All Locations"].code ===location || location==='')
    {
      alert("you haven't selected any location from the filter");
      return false;
    }
    else{
     // alert("you can proceed, selected location is " + location );
      return true;
    }
}