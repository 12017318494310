const shipmentReducer = (state={},action)=>{
    switch(action.type){
        case "SET_ALL":
            
            return{
                ...state,
                allShipments: action.payLoad
            }

            case "SET_COURIER":
                return{
                    ...state,
                    courier: action.payLoad
                }

            case "SET_COUNT":
                    return{
                        ...state,
                        count: action.payLoad
                    }

            case "SET_MANIFESTBUTTON":
                return{
                    ...state,
                    manifestButton: action.payLoad
                }
            case "SET_LOCATION":
                console.log("setLocation Reducer location", action.payLoad);
            return{
                ...state,
                location: action.payLoad
            }
            case "RESET_ALL":
                return {
                    ...state,
                    allShipments: {},
                    location:'',
                    courier:''
                }
                case "SET_SELECTED":
                    //console.log("SET_SELECTED Reducer", action.payLoad);
                    return {
                        ...state,
                        selected:action.payLoad
                    }
               case "SET_SHIPMENTGRID_CHKALL":
                return{
                    ...state,
                    shipmentGridChkAll: action.payLoad
                }
        default:
            return state;
    }
}
export default shipmentReducer;












