
export const IS_REFRESH_SHIPMENT = true;

export const FROM_ADDRESS  = {
    "All Locations":{  code :"All Locations",  name: "All Locations", fromStreet: "All Locations",fromCity:"All Locations", fromPostcode :"All Locations", fromState:"All Locations",fromCountry:"All Locations",email: "itsupport@pallion.com"}
    ,"8 Meeks Road":{ code :"8 Meeks Road",name: "Pallion Pty Limited", fromStreet: "8 Meeks Road",fromCity:"Marrickville", fromPostcode :"2204", fromState:"NSW",fromCountry:"AU",email: "itsupport@pallion.com"}
    , "66 Smith Street":{code :"66 Smith Street",name: "ABC Refinery", fromStreet: "66 Smith Street", fromCity:"Marrickville", fromPostcode :"2204", fromState:"NSW",fromCountry:"AU",email: "itsupport@pallion.com"}
    
};
/**********************************AuspostRelated*************************** */

//TODO:Nevin below code needs to be cleaned up after testing 
//export const AUSPOST_POSTAGE_TYPE =  Object.freeze( {'PARCELPOST':'AusPost', 'EXPRESSPOST':'AusEXPost'});
//export const AUSPOSTCOURIER =  'aus';

/**********************************AuspostRelated*************************** */
export const COURIER_SERVICES_SELECTED =  'auspost';

export const COURIER_PICKUP = 'pickup';
export const COURIER_OTHERS = 'others';



export const COURIER = {
    AUSPOST: {name:"auspost",displayName:'auspost',shortCode:'AusEXPost'}
    ,PICKUP: {name:"pickup",displayName:'pickup',shortCode:'pickup'},
    RONW: {name:"ronw",displayName:'ronw',shortCode:'ronw'},
    OTHERS: {name:"others",displayName:'others',shortCode:'others'},
    STARTRACK: {name:"startrack",displayName:'startrack',shortCode:'startrack'}
  };

//Postage table in sqlserver database
export const COURIER_SERVICES = Object.freeze(
    [
    {displayName:'AusPost-Express', postageCode:'AusEXPost',PostageType:"EXPRESS POST + SIGNATURE", postageName:"AusPost"}
    , {displayName:'AusPost-Parcel', postageCode:'AusPost' ,PostageType:"PARCEL POST + SIGNATURE", postageName:"AusPost"}
    ,{displayName:'PickUp', postageCode:'PickUp',PostageType:"PickUp", postageName:'PickUp'}
    // ,{displayName:'StarTrack-Express', postageCode:'StarTrackExp',PostageType:"Express", postageName:'StarTrack'}
    ,{displayName:'StarTrack-Premium', postageCode:'StarTrackPRM',PostageType:"Premium", postageName:'StarTrack'}
]);


//not used at the moment
export const OTHER_ORDER_FILTER = Object.freeze(['AusPost','AusEXPost','RonW','PickUp']);



export const CLICK_ACTION = {
                            CANCEL:"cancel"
                            , OK:"ok"
                            , EDIT:"edit"
                            , CLOSE:"close"
                            , UPDATE:"update"
                            , SAVE:"save"
                            , DELETE:"delete"
                            , ALERTMODALOK:"AlertModalOK"
                            ,ALERTMODALCLOSE :'AlertModalClose'
                            ,ERRORMODALCLOSE :'ErrorModalClose'
                            };

                            
export const DIMENSION = {
    WEIGHT:"weight"
    , HEIGHT:"height"
    , LENGTH:"length"
    , WIDTH:"width"
    ,SIZE:"size"    };

export const COMPONENT = {
    ShipmentGrid:"ShipmentGrid" };