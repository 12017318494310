import React, { useEffect, useState,Fragment } from 'react'
import ManifestGrid from './ManifestGrid';
import ManifestShipmentGrid from './ManifestShipmentGrid';
import ShipmentChart from './ShipmentChart'
import * as ManifestService from './ManifestService';
import DatePicker from 'react-datepicker';
import {format} from 'date-fns';
import AlertModal from '../CustomControl/AlertModal';
import {CLICK_ACTION, DIMENSION} from '../AppConstant/ShippingConstants'; 
import ErrorModal from '../CustomControl/ErrorModal';
import ProcessingModal from '../CustomControl/ProcessingModal';
import  {useSelector,useDispatch} from 'react-redux'
import allActions from '../redux/actions'
import * as sessionService from '../Session/StorageService'
import * as TextConfig from '../AppConstant/TextConfig';


const ManifestTab = (props) => {
  const dispatch = useDispatch();
  console.log('entered  ManifestTab');
  const [filterSpinner,setFilterSpinner]=useState(false);
  
  const courierFromStore = useSelector(state=>state.shipment.courier);

  const [startDate, setStartDate] = useState(new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"}));
  const [endDate, setEndDate] = useState(new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"}));
  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState(new Date());
  const [showManifestShipmentGrid, setManifestShipmentGrid] = useState(false);
  const [showProcessingModal, setProcessingModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [showError, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const [charData, setChartData] = useState([]);
  /* const [manifestList, setManifestList] = useState([
      { date: 1, manifest: 26, view: 'WjSander', download: 21, email: 'WjSander@email.com' },
      { date: 2, manifest: 45, view: 'Sara Sebastian', download: 19, email: 'Sara@email.com' },
      { date: 3, manifest: 50, view: 'Pandora', download: 16, email: 'Pandora@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 5, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 6, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 6, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 8, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 9, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' },
      { date: 4, manifest: 30, view: 'NSW Jewellers', download: 25, email: 'Jewellers@email.com' }
  ]); */

  console.log('Manifesttab new Date()',new Date());
  console.log('Manifesttab startDate',startDate);
  const [manifestList, setManifestList] = useState([]);
  /* const [shipmentSummary, setShipmentSummary] = useState([
    { totalValue: 1, manifestId: 26, customerAccount: 'WjSander', invoiceNumber: 21, salesShipmentNum: 'WjSander@email.com', customerName: "Name", postageCode: "AusPost" },
    { totalValue: 2, manifestId: 45, customerAccount: 'Sara Sebastian', invoiceNumber: 19, salesShipmentNum: 'Sara@email.com', customerName: "Name1", postageCode: "AusPost" },
    { totalValue: 3, manifestId: 50, customerAccount: 'Pandora', invoiceNumber: 16, salesShipmentNum: 'Pandora@email.com', customerName: "Name2", postageCode: "AusPost" },
    { totalValue: 4, manifestId: 31, customerAccount: 'NSW Jewellers', invoiceNumber: 25, salesShipmentNum: 'Jewellers@email.com', customerName: "Name3", postageCode: "AusPost" },
    { totalValue: 5, manifestId: 34, customerAccount: 'NSW Jewellers', invoiceNumber: 20, salesShipmentNum: 'Jewellers@email.com', customerName: "Name4", postageCode: "AusPost" },
    { totalValue: 6, manifestId: 35, customerAccount: 'NSW Jewellers', invoiceNumber: 27, salesShipmentNum: 'Jewellers@email.com', customerName: "Name5", postageCode: "AusPost" }
  ]); */

  const [shipmentSummary, setShipmentSummary] = useState([]);
  useEffect(() => {
    console.log('useEffect shipmentSummary entry');
    if (shipmentSummary.length>0){
      console.log('useEffect shipmentSummary length', shipmentSummary.length);
    setManifestShipmentGrid(true);
  }
  }, [shipmentSummary])

  useEffect(() => {
    console.log('useEffect startDate ', JSON.stringify(startDate));
    //var manifestParam = { startDate: startDate, endDate: endDate, createdBy: '' };
    var manifestParam = { startDate: format(datePickerStartDate, 'yyyy-MM-dd'), endDate: format(datePickerEndDate, 'yyyy-MM-dd'), createdBy: '' };
    
    console.log('useEffect manifestParam ', JSON.stringify(manifestParam));
    ManifestService.GetManifestSummary(manifestParam).then((response) => {
      console.log('useEffect GetManifestSummary response', JSON.stringify(response));
      if (!response.hasOwnProperty(TextConfig.STATUS))
      {
        setErrorMessage(TextConfig.ERR_CONNECTION_REFUSED);
        setErrorModal(true);
        return;
      }
      if (response.status === 200) {
        console.log('useEffect GetManifestSummary success', JSON.stringify(response.data));
        setManifestList(response.data);
      } 
      else if (response.status ===401) {
        console.log('useEffect GetManifestSummary 401', response);
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
      else if (response.response.status===401)
      {
        console.log('useEffect GetManifestSummary 401', response.response);
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
      else {
        console.log('useEffect GetManifestSummary else', JSON.stringify(response));
      }
    })
  }, [])

  const closeCancelModal =(props)=>{
    console.log('inside CLICK_ACTION.ERRORMODALCLOSE',props);
    if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE)
    {
      setErrorMessage('');setErrorModal(false);
    }
  }
 

  const AltertModalCallBack = () => {
    // console.log('inside AltertModalCallBack');
    setShowAlertModal(false);
    setErrorModal(false);
  }



  const ViewDetailsCallBack = (orders)=>{

    setManifestShipmentGrid(false);
    setShipmentSummary(orders);
    setChartData(GetChartData(orders));
  }



  const GetChartData=(orders)=>{
    var AusEXPost = orders.filter(order=>order.postageCode==='AusEXPost').length;
    var Auspost = orders.filter(order=>order.postageCode==='AusPost').length;
    var chartdata =  [];
    if (AusEXPost>0)
    {
      chartdata.push({ x: "AuspostExpress", y: AusEXPost, label: "AuspostExpress : "+AusEXPost  })
    }
    if (Auspost>0)
    {
      chartdata.push({ x: "AusParcelPost", y: Auspost ,label: "Auspost : "+Auspost });
    }
    //[{ x: "AuspostExpress", y: AusEXPost },{ x: "AusParcelPost", y: Auspost }]
    console.log('GetChartData  chartdata',chartdata);
    return chartdata;
  }
  const filterClickHandler = () => {
    setStartDate(datePickerStartDate);
    setEndDate(datePickerEndDate);
    setFilterSpinner(true);
    console.log('filterClickHandler datePickerStartDate', datePickerStartDate);
    console.log('filterClickHandler datePickerEndDate' + datePickerEndDate);
    console.log('filterClickHandler startDate' + startDate);
    console.log('filterClickHandler endDate' + endDate);
    var manifestParam = { startDate: format(datePickerStartDate, 'yyyy-MM-dd'), endDate: format(datePickerEndDate, 'yyyy-MM-dd'), createdBy: '' };
    ManifestService.GetManifestSummary(manifestParam).then((response) => {
      console.log('GetManifestSummary response', response);
      console.log('GetManifestSummary response.status', response.status);
      if (response.status === 200) {
        setManifestList(response.data);
        setFilterSpinner(false);
        setManifestShipmentGrid(false);
        if (response.data.length==0)
        {
         setAlertModalMessage('No manifest for the given date');
          setShowAlertModal(true);
        }
      } 
      else if (response.status===401)
      {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
      else if (response.response.status===401)
      {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }
      else {
        setManifestShipmentGrid(false);
        console.log('filterClickHandler GetManifestSummary else', JSON.stringify(response));
      }
    })

  }


  return (<div className="container-fluid">
    <div className="container-fluid pt-3 pb-3">
      <div className="row">

        <div className="col-sm"></div>
        <div className="col-sm">
        </div>
        <div className="col-sm"> <div className="divRightAlign" >Start Date : </div></div>
        <div className="col-sm"><DatePicker selected={datePickerStartDate} filterDate={maxDate => { return new Date() > maxDate }} dateFormat="dd/MM/yyyy" onChange={date => setDatePickerStartDate(date)}  > </DatePicker></div>
        <div className="col-sm"> <div className="divRightAlign" >End Date : </div></div>
        <div className="col-sm"> <DatePicker selected={datePickerEndDate} filterDate={maxDate => { return new Date() > maxDate }} dateFormat="dd/MM/yyyy" onChange={date => setDatePickerEndDate(date)}  > </DatePicker></div>
        <div className="col-sm"> 
      {/*   <button type="button" onClick={filterClickHandler} className="btn btn-dark btn-sm" >Filter
        
        </button> */}
        <button type="button" onClick={filterClickHandler} className="btn btn-dark btn-sm" >
        {filterSpinner ? (
                    <Fragment>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Fetching....
                    </Fragment>) : ("Refresh")}
        </button> 

        </div>
        <div className="col-sm"> </div>
        <div className="col-sm">
        </div>

      </div>
      <div className="row">
        <div className="col-sm">
          {showProcessingModal && (<ProcessingModal show={showProcessingModal} title="Create Manifest in progress"  ></ProcessingModal>)}
          <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={AltertModalCallBack}   ></AlertModal>
          { showError && <ErrorModal show={showError}  message={errorMessage} closeModal={closeCancelModal}   ></ErrorModal>}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm">
        <ManifestGrid manifestArr={manifestList}  spinner={false} CallBack={ViewDetailsCallBack} ></ManifestGrid>
      </div>
    </div>
    {showManifestShipmentGrid &&
    <div className="row">
      <div className="col-sm">
      <ManifestShipmentGrid shipmentSummary={shipmentSummary}></ManifestShipmentGrid>
      </div>
     {/*  <div className="col-sm"> 
      <ShipmentChart data ={charData}></ShipmentChart>
      </div> */}
    </div>
      }
  </div>
  
  )
}
export default ManifestTab;