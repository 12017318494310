import * as shippingConst from '../AppConstant/ShippingConstants';
import * as listHelper from '../Helper/ListHelper';

export const FilterShipments = (allShipments, courier, location) => {

    try {
        var allLocation = shippingConst.FROM_ADDRESS["All Locations"].code;
        console.log('FilterShipments  location', location);
        console.log('FilterShipments  location, courier', location, courier);
        var street = shippingConst.FROM_ADDRESS[location].fromStreet;
        console.log('FilterShipments street   ', street);
        var filteredList = null;
        if (location === allLocation) {
            console.log('FilterShipments All Locations IF  ', courier);
            filteredList = allShipments.filter(order => order.courierName === courier);
        }
        else {
            console.log('FilterShipments else', courier);
            filteredList = allShipments.filter(order => (order.courierName === courier && order.fromStreet === street));
        }
        console.log('GetFilteredOrders  ', filteredList);
        return filteredList;

    } catch (exception) {
            return exception;
    }
}


export const GetCountByCourier = (allShipments) => {
    try {
        var auspostCount = listHelper.GetCount(allShipments, shippingConst.COURIER.AUSPOST.name);
        var pickUpCount = listHelper.GetCount(allShipments, shippingConst.COURIER.PICKUP.name);
        var ronwCount = listHelper.GetCount(allShipments, shippingConst.COURIER.RONW.name);
        var starTrackCount = listHelper.GetCount(allShipments,shippingConst.COURIER.STARTRACK.name);
        return {
            auspost: auspostCount
            , pickUp: pickUpCount
            , ronw: ronwCount
            ,starTrack:starTrackCount
        }

    } catch (exception) {
        console.log('exception in shipmentFilter GetCountByCourier');
        return exception;
    }
}

//This is for setting the isSelected flag to true once the check box is seleted
export const SetSelectedFlag = (shipments, shipmentNum)=>{
    try {    
    var tempShipments  = [...shipments];
        var objToUpdate = tempShipments.find(obj=>
            {return obj.salesShipmentNum ===shipmentNum });
            objToUpdate.isSelected = !objToUpdate.isSelected;
            return tempShipments;
        } catch (exception) {
            console.log('exception in shipmentFilter SetSelectedFlag');
            return exception;
        }
}

//checkBox all 
//This is for toggling  the isSelected flag for all to true or false  once the check box all from header is seleted
export const ToggleSelectAll = (shipments, flag) => {
    try {
        var tempShipments = [...shipments];
        tempShipments.forEach(element => { element.isSelected = flag });
        return tempShipments;
    } catch (exception) {
        console.log('exception in shipmentFilter ToggleSelectAll');
        return exception;
    }
}

export const IsManifestButton = (selectedShipments) => {
    try {
        var isTrackingAvailable = selectedShipments.find(shipment => shipment.trackingNumber !== null);
        if (typeof isTrackingAvailable !== 'undefined') {
            return true;
        }
        else {
            return false;
        }
    } catch (exception) {
        console.log('exception in shipmentFilter IsManifestButton');
        return exception;
    }
}
