import React, { useState,useEffect } from 'react';
import './LocationUpdateDropdown.css'

//Location DropDown
const LocationUpdateDropdown = (props) => {
        const [selectedOption,setSelectedOption] = useState(props.location);
        const [disabled, setDisabled] = useState(props.disabled);//TODO:Nevin, remove after testing not used, instead assigning props.disabled directly
        //console.log('LocationUpdateDropdown props.salesShipmentNum disabled, props.disabled ', disabled, props.disabled, props.salesShipmentNum)
        const changeHandler =(event)=>{
            /* console.log('LocationUpdateDropdown changeHandler event',event);
            console.log('LocationUpdateDropdown changeHandler event.target.value',event.target.value);
            console.log('LocationUpdateDropdown changeHandler event.target.name',event.target.name); */
            let selectedLocation = event.target.value;
            let salesShipmentNum = event.target.name;
            setSelectedOption(event.target.value);
            props.shipmentGridCallBack({newLocation:selectedLocation,salesShipmentNum:salesShipmentNum});
        }
            const ToDisable = (prop)=>{
             var status = (prop!==null)?true:false;
             console.log('LocationUpdateDropdown status',status )
            }
    return (
        <div >
        <select  value={selectedOption}  disabled={props.disabled}  className="locationUpdateDropdown"   id={`drp${props.salesShipmentNum}`}  name={props.salesShipmentNum}  onChange={changeHandler} >
            <option value="8 Meeks Road"   >8 Meeks Road</option>
            <option value="66 Smith Street"  >66 Smith Street</option>
            
        </select>
        </div> 
    )
}
export default LocationUpdateDropdown;