import React, { useState } from 'react';
 import './LocationFilter.css';
const LocationFilter = (props) => {
    console.log('LocationFilter props.selected',props.selected);
    const [selectedOption,setSeletedOption] = useState(props.selected);
    
    const changeHandler =(event)=>{
        console.log('LocationFilter changeHandler event',event.target.value);
        setSeletedOption(event.target.value);
        props.callBack(event);
    }


    return (
        <select value={selectedOption} className="myCustomDrp" onChange={changeHandler}>
            <option value="All Locations" >All Locations</option>
            <option value="8 Meeks Road"  >8 Meeks Road</option>
            <option value="66 Smith Street">66 Smith Street</option>
        </select>
    )
}
export default LocationFilter;