import React from 'react';
import axios from 'axios';
//import * as shipmentConst from '../AppConstant/ShippingConstants';
import * as UrlConfig from '../AppConstant/UrlConfig';
export const SubmitPickUp = async (pickUpDetails) => {
    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    console.log('inside the UpdatePostage  method');
    
    try {
            const response = await axios.post(UrlConfig.SUBMIT_PICKUP_URL
                ,JSON.stringify(pickUpDetails)
                ,{
                    headers:{'Content-Type':'application/json', "Authorization":`Bearer ${authToken}`}
                }
            )
    return response;
    } catch (exception) {
        console.log('PickUpService  SubmitPickUp  exception',exception);
        return exception;
    }
}



export const GetPickUpDetails = (selectedOrders, comments, user) => {
    console.log('inside  GetPickUpDetails');
    var shipmentNoList = selectedOrders.map(order=>order.salesShipmentNum);
    return { 
        ShipmentNumbers: shipmentNoList
        , Comments: comments
        ,CreatedBy : user
    }
}