import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

const CreateManifestModal = (props) => {

    return (
        <Modal centered  show={props.show} onHide={() => {props.onClick({status:false})}} centered>
            <Modal.Header closeButton>
            <Modal.Title>
        
            </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                You are going to create manifest for {props.details.selectedShipments} out of {props.details.validShipments} eligible  
               <br></br> There are {props.details.unProcessed} un processed shipments as well
               <br></br> Do you want to proceed still?
            </Modal.Body>

            <Modal.Footer>
            <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="text-left"><button className="btn btn-dark btn-sm" onClick={()=>{props.onClick({status:false})}} >Cancel</button></div>
                        </div>
                        <div className="col">
                            <div className="text-right">
                                <button className="btn btn-danger btn-sm" onClick={()=>{props.onClick({status:true})}} >Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateManifestModal;