 import {createStore} from 'redux'

import rootReducer from './reducers'
import * as config from './reduxConstants';

function saveToLocalStorage(state) {
    try {
        console.warn(' in store saveToLocalStorage state', state);
        const serialisedState = JSON.stringify(state);
        //console.warn(' in store saveToLocalStorage serialisedState', serialisedState);
        sessionStorage.setItem(config.REDUX_USER_STATE, serialisedState);
    } catch (exception) {
        console.warn('exception in store saveToLocalStorage', exception);
    }
}


function loadFromLocalStorage()
{
    try {
        const serialisedState  = sessionStorage.getItem(config.REDUX_USER_STATE);
        if (serialisedState== null) return undefined;
        //console.warn(' in store loadFromLocalStorage serialisedState', serialisedState);
        return JSON.parse(serialisedState);

    }catch (exception)
    {
        console.warn('exception in store loadFromLocalStorage', exception);
    }
}

const store = createStore(rootReducer,loadFromLocalStorage());

store.subscribe(()=>{console.log('store.subscribe',  store.getState())}); 
store.subscribe(()=>{saveToLocalStorage(store.getState())}); 
export default store;