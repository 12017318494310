import axios from 'axios';
//import * as shipmentConst from '../AppConstant/ShippingConstants';
import * as UrlConfig from '../AppConstant/UrlConfig';

export const Authenticate = async (userName, password) => {
    try {
        const response = await axios.post(UrlConfig.AUTH_URL,
            {
                userName
                , password
            });
        return response;
    } catch (exception) {
        if (!exception.response) {
            return exception;
        }
        return exception.response;
    }
}


