
import * as shippingConst from '../AppConstant/ShippingConstants';

export const  GetCount =(list,fieldName)=>{
    var filteredOrders = list.filter((order)=>
       (typeof order.courierName !== 'undefined' &&
        order.courierName.toLowerCase().search(fieldName)>-1)
    );
    return filteredOrders.length;
}

export const  GetOtherOrderCount =(list,fieldName)=>{
    var filteredOrders = list.filter((order)=>
      !shippingConst.OTHER_ORDER_FILTER.includes(order.postageCode)
    );
    console.log('GetOtherOrderCount otherOrders ', filteredOrders);
    return filteredOrders.length;
}

export const  GetOtherOrders =(list,fieldName)=>{
    var filteredOrders = list.filter((order)=>
      !shippingConst.OTHER_ORDER_FILTER.includes(order.postageCode)
    );
    console.log('GetOtherOrders otherOrders ', filteredOrders);
    return filteredOrders;
}

//select the orders whose isSelected flag true
export const GetisSelectedTrueOrders =(shipmentOrders)=>{
    try {
    console.log('GetisSelectedTrueOrders  shipmentOrders', shipmentOrders);
    var selectedOrders = shipmentOrders.filter(order=>order.isSelected===true && order.trackingNumber==null);
    console.log('GetisSelectedTrueOrders ', selectedOrders);
    return selectedOrders;
    }catch(exception)
    {
        console.log(' ListHelper GetisSelectedTrueOrders  exceptipon ', exception);
    }
}

export const GetFilteredOrders =(shipmentOrders,courier, location)=>{    
   /*  console.log('GetFilteredOrders shipmentOrders  ', shipmentOrders);
    console.log('GetFilteredOrders courier  ', courier);
    console.log('GetFilteredOrders location  ', location); */
    var allLocation = shippingConst.FROM_ADDRESS["All Locations"].code;
    //console.log('GetFilteredOrders allLocation code   ', location);
    var street = shippingConst.FROM_ADDRESS[location].fromStreet;
    //console.log('GetFilteredOrders street   ', street);
    var filteredList = null;
   if (location===allLocation)
   {
    //console.log('GetFilteredOrders All Locations IF  ', courier);
    filteredList = shipmentOrders.filter(order=> order.courierName === courier );
   }
   else {
    //console.log('GetFilteredOrders else', courier);
    filteredList = shipmentOrders.filter(order=> (order.courierName === courier && order.fromStreet ===street));
   }
    //console.log('GetFilteredOrders  ', filteredList);
    return filteredList;

}

//Adding sequential number property to objects
export const AddSeqNo = (orders)=>{
    console.log('AddSeqNo orders',orders)
    var counter = 0;
    orders.forEach((element, index) => {
        element.seqNo = index;
     });
     console.log('AddSeqNo orders',orders)
     return orders;
}



