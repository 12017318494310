import React,{useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/react-fontawesome';
import './ShipmentPage.css'
import ShipmentActionBar from './ShipmentActionBar';
import ShipmentGrid from './ShipmentGrid';
import * as shippingConst from '../AppConstant/ShippingConstants'
import ProcessingModal from '../CustomControl/ProcessingModal';
import AlertModal from '../CustomControl/AlertModal';
import ErrorModal from '../CustomControl/ErrorModal'
import * as shipmentService from './shipmentService';
import * as shipmentFilter from './shipmentFilter';

import  {useSelector,useDispatch} from 'react-redux'
import allActions from '../redux/actions'
import CourierTabBar from './CourierTabBar';

import {CLICK_ACTION} from '../AppConstant/ShippingConstants';
import * as TextConfig from '../AppConstant/TextConfig';

import * as sessionService from '../Session/StorageService'


const ShipmentPage = (props) =>{
  const dispatch = useDispatch();
  const allShipmentFromStore = useSelector(state=>state.shipment.allShipments);
  const locationFromStore = useSelector(state=>state.shipment.location);
  const courierFromStore = useSelector(state=>state.shipment.courier);
  console.log('ShipmentPage allShipmentFromStore', JSON.stringify(allShipmentFromStore));
  //console.log('ShipmentPage courierFromStore',courierFromStore);
    const[showFilterBar,setFilterBar] =useState(true); 
    const [axRefresh, setAxRefresh]=useState(false);
    const[showSOGrid, setShowSOGrid]=useState(false);    

    const[clickedCount, doSubmit]=useState(0);//GMZK

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showProcessingModal, setProcessingModal] = useState(false);
    const [processingModalMsg, setProcessingModalMsg] = useState('');

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [showError,setError]= useState(false);
    const [trigger,setTrigger]= useState('');
    const AltertModalCallBack =()=>{
    }
   
    const closeCancelModal =(props)=>{
      console.log('inside CLICK_ACTION.ERRORMODALCLOSE',props);

      if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE && props.trigger === TextConfig.SESSION_EXPIRY)
      {
        setTrigger('');
        setAlertModalMessage('');setError(false);
        sessionStorage.removeItem("persistantUser");
        dispatch(allActions.userActions.logOut());
      }
      if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE)
      {
        setAlertModalMessage('');setError(false);
      }
    }


  useEffect(() => {
    let mounted = true;
    console.log('ShipmentPage useEffect');
    initializeLocation();
    fetchShipments({axRefresh : axRefresh, startDate : startDate, endDate : startDate, courierFromStore : courierFromStore});
    return () => mounted = false;
  }, []);


  const initializeLocation = ()=>{
    console.log('ShipmentPage useEffect initializeLocation entry',locationFromStore );
    if (locationFromStore=== '' || locationFromStore===undefined)
    {
      console.log('ShipmentPage useEffect initializeLocation',locationFromStore );
      dispatch(allActions.shipmentActions.setLocation(shippingConst.FROM_ADDRESS["All Locations"].code))
    }
    if (courierFromStore=== '' || courierFromStore===undefined)
    {
      console.log('ShipmentPage useEffect initializeLocation',courierFromStore );
      dispatch(allActions.shipmentActions.setCourier(shippingConst.COURIER_SERVICES_SELECTED))
    }
  }

     
 const refreshButtonCallBack = (props)=>
 {
  console.log('shipmentPage  refreshButtonCallBack props',props);
  fetchShipments(props);
 }



  const fetchShipments = (props) => {
    setProcessingModalMsg('Fetching Shipments')
    setProcessingModal(true);
    shipmentService.GetShipments(props.axRefresh, props.startDate, props.endDate, props.courierFromStore).then((response) => {
      //console.log('fetchShipments response', response);
      setProcessingModal(false);

      if (!response.hasOwnProperty(TextConfig.STATUS))
      {
        setAlertModalMessage(TextConfig.ERR_CONNECTION_REFUSED);
        setError(true);
        return;
      }



      if (response.status === 200) {
        dispatch(allActions.shipmentActions.setAll(response.data));
        var selectedShipments = shipmentFilter.FilterShipments(response.data,courierFromStore,locationFromStore);
        toggleManifest(selectedShipments,'fetchShipments');
        var countByCourier = shipmentFilter.GetCountByCourier(response.data);
        dispatch(allActions.shipmentActions.setCount(countByCourier));
        dispatch(allActions.shipmentActions.setSelected(selectedShipments));
        setShowSOGrid(true)
      }
      else if (response.status === 500)
      {
        console.log('fetchShipments response.status 500 response', response);
        setAlertModalMessage(TextConfig.INT_SERVER_ERROR);
        setError(true);
      }
      if (response.status===401)
      {
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      }

    })

  }
//Selected
const toggleManifest =(selectedShipments,caller)=>{
  //console.log('ShipmentPage toggleManifest caller', caller);
  var showManifestButton = shipmentFilter.IsManifestButton(selectedShipments);
  dispatch(allActions.shipmentActions.setManifestButton(showManifestButton));
}


    return (

        <div className="container-fluid">
    
          <div className="container-fluid">
       
          <div className="row" style={{height:'2em'}}>
            {/*  this is to add vertical space  */}
            </div>
            <div className="row">
              
              <div className="col-sm">
              {showSOGrid &&  <CourierTabBar toggleManifestCallBack={toggleManifest} ></CourierTabBar>}
              </div>
              
            </div>
          </div>
          <div className="container-fluid pt-3 pb-3">
            <div className="row">
            {showFilterBar && <ShipmentActionBar  toggleManifestCallBack={toggleManifest}    callBack={refreshButtonCallBack} clickSubmit={clickedCount} ></ShipmentActionBar>}            
            </div> 
            <div className="row">
              <div className="col-sm">
                {showProcessingModal && (<ProcessingModal show={showProcessingModal} title={processingModalMsg}  ></ProcessingModal>)}
                <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={AltertModalCallBack}   ></AlertModal>
                { showError && <ErrorModal show={showError} trigger={trigger}  message={alertModalMessage} closeModal={closeCancelModal}   ></ErrorModal>}
              </div>
            </div>
          </div>
    
    
          <div >
            {showSOGrid && 
          <ShipmentGrid  callBack={refreshButtonCallBack}   ></ShipmentGrid>}
          </div>
          <div className="container-fluid pt-3 pb-3">
            <div className="row">
              <div className="col-sm"> </div>
              <div className="col-sm"> </div>
              <div className="col-sm"></div>
              <div className="col-sm"> </div>
              <div className="col-sm"> </div>
              <div className="col-sm"> </div>
              <div className="col-sm"></div>
              <div className="col-sm"> </div>
            </div>
          </div>
          <div className="col-sm"> <button type="button"  onClick={() => doSubmit(prev=>prev+1)} className="btn btn-dark btn-sm float-right" >Manual Tracking Number</button></div> 
        </div>//GMZK
    
      );

}
export default ShipmentPage;