
export  const  EDIT_SIZE_TITLE =    'Please enter new values for Size(L,W,H)';



//http response field
export const STATUS='status';




//Error Messages:
export const SESSION_EXPIRY = 'SessionExpiry';
export const INT_SERVER_ERROR = 'InternalServerError, An error occured while processing your request.';
export const ERR_CONNECTION_REFUSED = 'Network error, Not able to connect to Api';
export const MANIFEST_CREATION_FAILED = 'Create Manifest Failed';



//axios constants

export const SESSION_EXPIRED = 'Session expired, please login to continue';


export const  SHIPMENT_SUCCESS =  "Shipment created successfully !";



//create shipment related 

export const NO_ELIGIBLE_ORDERS_FOR_SHIPMENTS = "no eligible orders selected, you might have selected a order which is proceesed and tracking number available";


//location drop down 

export const NO_LOCATION_SELECTED = "you haven't selected any location from the filter";
export const ALL_LOCATIONS = "All Locations";




