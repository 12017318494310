import React, { useEffect, useState } from 'react';
import palloysSmallLogo from '../Image/Palloys_Logo_CrispWhite-sml.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
//import { useAuth } from "../context/auth"; //16-DEC-2020
import { useSelector, useDispatch } from 'react-redux';
import  allActions   from '../redux/actions';
import * as config from '../redux/reduxConstants'

import * as sessionService from '../Session/StorageService';


const HomeTopNavBar = (props) => {
    const reducerState = useSelector(state=>state);
    const userFromStore = useSelector(state=>state.userReducer.user);
    const dispatch = useDispatch();
    const [currentuser, sessionUserHandler] = useState(JSON.parse(sessionStorage.getItem('user'))||'');
    const LogOut = () =>{
        dispatch(allActions.userActions.logOut());
        dispatch(allActions.shipmentActions.resetAll());
        sessionService.LogOut();
      }

      useEffect(()=>{
        //console.log('HomeTopNavBar useEffect  currentuser',currentuser);
        sessionUserHandler(currentuser);
      },[currentuser]);


    return (
        <div className="container-fluid">
            <div className="row">
            <div className="col-sm">
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
            {/*   <!-- Brand --> */}
            <a className="navbar-brand" href="#">
            <img src={palloysSmallLogo} className="img-fluid"  />
            </a>
            <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <div className="nav-link" > <FontAwesomeIcon icon={faUser} title="User"  color="white" id="userIcon" size='1x' ></FontAwesomeIcon> &nbsp;
        
         {userFromStore && userFromStore.firstName}
         </div>
            </li>
            <li className="nav-item">
                <div className="nav-link" ><FontAwesomeIcon icon={faSignOutAlt} color="white"  title="Logout" id="signOut" size='1x'  onClick={LogOut} ></FontAwesomeIcon>&nbsp;&nbsp;Logout</div>
            </li>
        </ul>
    </div>
        </nav>
        </div>
        </div>
        </div>
    )
}

export default HomeTopNavBar;