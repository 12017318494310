import axios from 'axios';
//import * as shipmentConst from '../AppConstant/ShippingConstants';
import * as UrlConfig from '../AppConstant/UrlConfig';

export const DownLoadPdf = async (orderId, location, courier) => {
    console.log('DownLoadPdf courier', courier);
    var url = GetOrderSummaryUrl(courier.toLowerCase())
    console.log('DownLoadPdf orderId', orderId);
    console.log('DownLoadPdf url', url);

    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");

    var orderSumaryRequest = { orderId: orderId
        , location: location
        ,courier:courier
        , user: ''
     };

    try {

        const response = await axios.post(url,
            JSON.stringify(orderSumaryRequest),
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
                ,responseType: 'blob'
            }
         
        )
        return response;
    } catch (exception) {
        return exception;
    }

}

/* 
export const DownLoadPdf = async (orderId, location, courier) => {
    console.log('DownLoadPdf courier', courier);
    var url = GetOrderSummaryUrl(courier.toLowerCase())
    console.log('DownLoadPdf orderId', orderId);

    const tokens = JSON.parse(sessionStorage.getItem("tokens"));
    const authToken = sessionStorage.getItem("AuthToken");
    try {

        const response = await axios.get(UrlConfig.ORDERSUMMARY_URL,
            {
                headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${authToken}` }
                , params :{ orderId: orderId, location:location }
                ,responseType: 'blob'
            }
         
        )
        return response;
    } catch (exception) {
        return exception;
    }

}
 */



const GetOrderSummaryUrl =(courier)=>{
switch(courier)
{
    case 'startrack':
        return UrlConfig.STARTRACK_ORDERSUMMARY_URL;
    case 'ausexpost':
    case 'auspost':
        return UrlConfig.ORDERSUMMARY_URL;
}


}



