import React, {Fragment,useState,useEffect}from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import {CLICK_ACTION} from '../AppConstant/ShippingConstants'; 
import LocationFilter from '../Home/LocationFilter';
import Checkbox from '../CustomControl/Checkbox';
import CreateManifestModal from '../Home/CreateManifestModal';
import ManifestResponseModal from '../Home/ManifestResponseModal';
import PostageUpdateModal from '../Home/PostageUpdateModal';
import CommonProgressModal from '../CustomControl/CommonProgressModal';
import SubmitPickUpModal from '../Home/SubmitPickUpModal';
import * as ListHelper from '../Helper/ListHelper';
import * as PostageHelper from '../Helper/PostageHelper';
import DatePicker from 'react-datepicker';

import * as ManifestHelper from '../Helper/ManifestHelper';
import * as validationHelper from '../Helper/validationHelper';
import * as shipmentHelper from './shipmentHelper';
import * as shipmentService from './shipmentService';
import * as PickUpService from '../Home/PickUpService';
import  * as PostageService from '../Home/PostageService';
import * as shippingConst from '../AppConstant/ShippingConstants'
import * as TextConfig from '../AppConstant/TextConfig';

import {useDispatch,useSelector} from 'react-redux'
import allActions from '../redux/actions';
import * as shipmentFilter from './shipmentFilter';
import SwitchCourierDropdown from './SwitchCourierDropdown';
import ProcessingModal from '../CustomControl/ProcessingModal';
import AlertModal from '../CustomControl/AlertModal';
import ErrorModal from '../CustomControl/ErrorModal';
import * as sessionService from '../Session/StorageService'
import  * as ShipmentPage from './ShipmentPage';

const ShipmentActionBar =(rootprops)=>{
    const [showSubmitPickUpModal, setSubmitPickUpModal]=useState(false);
    const [submitPickUpData, setSubmitPickUpData]=useState([]);
    const [shipmentOrders, setShipmentOrders] = useState({});
    
    
    const dispatch = useDispatch();
    const userFromStore = useSelector(state=>state.userReducer);
    const locationFromStore = useSelector(state=>state.shipment.location);
    const allShipmentFromStore = useSelector(state=>state.shipment.allShipments);
    const courierFromStore = useSelector(state=>state.shipment.courier);
    const selectedShipmentsFromStore = useSelector(state=>state.shipment.selected);
    const showManifestFromStore = useSelector(state=>state.shipment.manifestButton);
    const selectAllFromStore = useSelector(state=>state.shipment.shipmentGridChkAll);
    //console.log('Home userFromStore ',userFromStore);

    //console.log('ShipmentActionBar showManifestFromStore ',showManifestFromStore);

    //console.log('ShipmentActionBar courierFromStore pageload',courierFromStore);
    
    const [startDate, setStartDate] = useState(new Date());
   
    const [endDate, setEndDate] = useState(new Date());
    const [showSubmitProgress, setSubmitProgress] = useState(false);
    const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
    const [datePickerEndDate, setDatePickerEndDate] = useState(new Date());

    const [currentuser, sessionUserHandler] = useState(JSON.parse(sessionStorage.getItem('user')));

    const [showManifest, setShowManifest] = useState(false);
    const [showManifestModal, setManifestModal] = useState(false);
    
    
    const [showManifestReponseModal, setShowManifestResponse] = useState(false);
    const [manifestResponseMessage, setManifestResponseMessage] = useState('');
    const [manifestDetails, setManifestDetails] = useState({});
  
    const [showPostageUpdateModal, setPostageUpdateModal]=useState(false);
    const [postageUpdateModalMsg, setPostageUpdateModalMsg]=useState('');
    const [postageSelected, setPostageSelected]=useState('');
    
    const [alertModalMessage, setAlertModalMessage] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);
  
    const [showError,setError]= useState(false);
    const[errorMessage, setErrorMessage]=useState('');


    const [showCommonProgressModal, setCommonProgressModal]=useState(false);
    
    const [commonProgressModalTitle, setCommonProgressModalTitle]=useState('');
  
  
    const [isRefresh, setRefresh] = useState(true);
    const [refreshFromAx, setRefreshFromAx] = useState(false);
  
    const [refreshCheckBox, setRefreshCheckBox] =useState(false);



    const [showProcessingModal, setProcessingModal] = useState(false);
    const [processingModalMsg, setProcessingModalMsg] = useState('');

    const closeCancelModal =(props)=>{
        console.log('inside CLICK_ACTION.ERRORMODALCLOSE',props);
        if (props.action === CLICK_ACTION.CLOSE && props.source === CLICK_ACTION.ERRORMODALCLOSE)
        {
          setErrorMessage('');setError(false);
        }
      }
      useEffect(() => {
        if(rootprops.clickSubmit!=0)
        submitClickHandler();
      }, [rootprops.clickSubmit]);//GMZK
      
    

    const LocationfilterHandler = (event)=>{
        //console.log('LocationfilterHandler locationFromStore ',locationFromStore);
        let updatedAddress= shippingConst.FROM_ADDRESS[event.target.value];
        //console.log("LocationfilterHandler code", shippingConst.FROM_ADDRESS[event.target.value].code);
        //console.log("completeAddress", updatedAddress);
        dispatch(allActions.shipmentActions.setLocation(shippingConst.FROM_ADDRESS[event.target.value].code))
        var selectedShipments = shipmentFilter.FilterShipments(allShipmentFromStore,courierFromStore,shippingConst.FROM_ADDRESS[event.target.value].code);
         //console.log('LocationfilterHandler selectedShipments ',selectedShipments);
        dispatch(allActions.shipmentActions.setSelected(selectedShipments));
        rootprops.toggleManifestCallBack(selectedShipments);
    }

    
    const ClearCheckBoxHeader = () => {
        dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
        };

 


    const RefreshAxChkHandler = (e) => {
        var isChecked = e.target.checked;
        if (isChecked) {

            setRefreshCheckBox(true);
        }
        else {
            setRefreshCheckBox(false);
        }
        //console.log('RefreshAxChkHandler object e', isChecked)
        //console.log('RefreshAxChkHandler refreshCheckBox', refreshCheckBox)
    }
      



    const manifestModalCallBack = (props) => {
        console.log('manifestModalCallBack props', props.status);
        if (props.status === false) {
            setManifestModal(false);
            return false;
        }
        else {
            setManifestModal(false);
            setProcessingModalMsg("Creating Manifest, Please wait.....")
            setProcessingModal(true);
            //TODO:Nevin:below snippet is used in two places, refactor to avoid duplication
            var shipmentsForManifest = selectedShipmentsFromStore.filter(order =>
                order.isSelected === true &&
                order.trackingNumber !== null
            );
            console.log('CreateManifest shipmentsForManifest', shipmentsForManifest);
            var shipmentIds = [...new Set(shipmentsForManifest.map(shipment => shipment.shipmentId))]
            shipmentService.CreateManifest(shipmentIds, userFromStore.user, locationFromStore, courierFromStore).then((response) => {
                console.log('CreateManifest response', response);
                
                if (response.status === 200) {
                    console.log('CreateManifest success response', response);
                    console.log('CreateManifest success response.data', response.data);
                    setProcessingModal(false);
                    setShowManifestResponse(true);
                    //console.log('response.data["successResponse"]["order"]', response.data["successResponse"]['order']);
                    //setManifestResponseMessage(response.data["successResponse"]['order'].order_id);
                    setManifestResponseMessage(response.data.order_id);
                    rootprops.callBack({ source:'CreateManifest',axRefresh : false, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});

                    dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
              
                }else if (response.status===401)
                {
                    dispatch(allActions.userActions.logOut());
                    sessionService.SetSessionExpiry(); 
                }else if (response.status===500)
                {
                    setProcessingModal(false); 
                    //var errorMessage= response.response.data['shipmentPriceError'];
                    setErrorMessage(`${TextConfig.MANIFEST_CREATION_FAILED}, ${response.data}`)
                    setError(true);
                }
                else if (response.response.status===401)
                {
                dispatch(allActions.userActions.logOut());
                sessionService.SetSessionExpiry();
                }
                else {
                    setProcessingModal(false);
                    console.log('CreateManifest behaved differently please check ');
                }
            })

        }
    }


    const manifestButtonHandler = (props) => {

        if (!validationHelper.isValidLocation(locationFromStore)) return false;

        var shipmentsForManifest = selectedShipmentsFromStore.filter(order =>
            order.isSelected === true &&
            order.trackingNumber !== null
        );

        if (shipmentsForManifest.length == 0) {
            alert(" selected order shipment no is not generated or havent selected any eligible shipment");
        }
        else {
            console.log('manifestButtonHandler else condition');
            var manifestDetails = ManifestHelper.GetManifestAlertDetails(selectedShipmentsFromStore, shipmentsForManifest);
            console.log('var manifestDetails', manifestDetails);
            setManifestDetails(manifestDetails);
            setManifestModal(true);
        }

    }


    //  switch courier drop down selected 
    //PostageUpdateModalCallBack will be triggered after confirmation
    const switchCourierCallBack = (props) => {
        console.log('ShipmentAction switchCourierCallBack', props);
        console.log('ShipmentAction switchCourierCallBack selectedShipmentsFromStore', selectedShipmentsFromStore);


        var selectedOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore)
        if (selectedOrders.length == 0) {
            alert('you haven`t selected any orders or might have selected order with tracking number');
        }
        else {
            setPostageUpdateModalMsg(`Are you sure to switch for ${selectedOrders.length} order[s]`);
            setPostageSelected(props);
            setPostageUpdateModal(true);
        }
    }
  
    
    const SubmitPickUpCallBack = (props) => {
        console.log('SubmitPickUpCallBack props', props);
        if (props.action === CLICK_ACTION.CANCEL) {
            console.log('SubmitPickUpCallBack action cancelled');
            setSubmitPickUpModal(false);
        }
        else if (props.action === CLICK_ACTION.OK) {
            console.log('SubmitPickUpCallBack action Ok');
            setSubmitPickUpModal(false);
            var pickUpOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore);
            var pickupDetails = PickUpService.GetPickUpDetails(pickUpOrders, props.PickUpComment, currentuser.firstName);
            console.log('submit pickup pickupPostObject ', pickupDetails);

            setCommonProgressModalTitle('Processing pickup please wait...........');
            setCommonProgressModal(true);

            PickUpService.SubmitPickUp(pickupDetails).then((response) => {
                console.log('SubmitPickUp pickupDetails' + JSON.stringify(response));
                setCommonProgressModal(false);
                if (response.status === 200) {
                    ClearCheckBoxHeader();
                    setAlertModalMessage("Pickup processed successfully");
                    setShowAlertModal(true);
                    //TODO:Nevin bellow call can be omitted instead remove the particlar shipwmnt form the shipment list and assign to selectedShipments
                    rootprops.callBack({ source:'SubmitPickUp',axRefresh : false, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});
                } 
                else if (response.status===401)
                {
                    dispatch(allActions.userActions.logOut());
                    sessionService.SetSessionExpiry();
                }
                else {
                    console.log('PickUpService.SubmitPickUp error', response.message);
                    setProcessingModal(false);
                }
            }
            )
        }
    }


//Submit Button click
const submitClickHandler = () => {
   
    if (shippingConst.FROM_ADDRESS[TextConfig.ALL_LOCATIONS].code ===locationFromStore || locationFromStore==='')
    {
      alert(TextConfig.NO_LOCATION_SELECTED);
      return false;
    }
   /*  else{
      alert("you can proceed, selected location is " +locationFromStore );
    } */
    var selectedOrders = selectedShipmentsFromStore.filter(order =>
      order.isSelected === true &&
      order.shipmentPriceError === null &&
      order.trackingNumber === null);
    //console.log('selectedOrders', selectedOrders);


    if (selectedOrders.length == 0) {
      alert(TextConfig.NO_ELIGIBLE_ORDERS_FOR_SHIPMENTS);
      ClearCheckBoxHeader();
      setRefreshCheckBox(false);
      return false;
    }

    //TODO:Nevin; configure
   // if (courierState === shippingConst.COURIER_PICKUP)
   if (courierFromStore === shippingConst.COURIER_PICKUP)
    {
      console.log('submit pickup submit');
      setSubmitPickUpData(selectedOrders);
      setSubmitPickUpModal(true);
      return false;
    }

    setProcessingModal(true);
    setProcessingModalMsg("Creating shipment Please wait....")

    shipmentService.CreateShipment(selectedOrders,courierFromStore).then((response) => {
        
        console.log('submitClickHandler courierFromStore',courierFromStore);
        
        console.log('submitClickHandler status raw response' , response);
        console.log('submitClickHandler status JSON.stringify(response)' , JSON.stringify(response));
        if (!response.hasOwnProperty(TextConfig.STATUS))
        {
          setProcessingModal(false); 
          setAlertModalMessage(TextConfig.ERR_CONNECTION_REFUSED);
          setShowAlertModal(true);
          return;
        }
      
      if (response.status === 200) {
        setProcessingModal(false);
        setShowAlertModal(true);
        setAlertModalMessage(TextConfig.SHIPMENT_SUCCESS);
        console.log('submitClickHandler status 200');
        //TODO:Nevin callback object can be moved to a helper function to create the object
        rootprops.callBack({axRefresh : false, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});
        dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
      }else if (response.status === 500)
      {
        console.log('submitClickHandler status 500');
        console.log('submitClickHandler status 500, response.data',response.data);
        setProcessingModal(false); 
        //var errorMessage= response.response.data['shipmentPriceError'];
        setErrorMessage(`${response.data}`)
        setError(true);
        rootprops.callBack({axRefresh : false, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});
        dispatch(allActions.shipmentActions.setShipmentGridChkAll(false));
      }
      else if (response.status === 204)
      {
        console.log('submitClickHandler status 204');
        setProcessingModal(false); 
        setAlertModalMessage(TextConfig.SHIPMENT_SUCCESS);
        setShowAlertModal(true);
      }
      else if (response.status===401)
      {
        console.log('submitClickHandler response.status 401');
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } 
      else if (response.response.status===401)
      {
        console.log('submitClickHandler response.response.status 401');
        dispatch(allActions.userActions.logOut());
        sessionService.SetSessionExpiry();
      } 
      else {
        console.log('submitClickHandler status error', response.message);
        setProcessingModal(false);
      }
    })
  }

    const PostageUpdateModalCallBack = (props) => {
        console.log('PostageUpdateModalCallBack action', props);
        if (props.action == false) {
            setPostageUpdateModal(false);
            setPostageUpdateModalMsg('');
            return false;
        } else if (props.action == true) {
            setPostageUpdateModal(false);
            setPostageUpdateModalMsg('');

            setCommonProgressModalTitle(`Updating courier details to ${props.postage}`);
            setCommonProgressModal(true);
            console.log('PostageUpdateModalCallBack selectedShipmentsFromStore : ', selectedShipmentsFromStore);
            var selectedOrders = ListHelper.GetisSelectedTrueOrders(selectedShipmentsFromStore);
            var updateObj = PostageHelper.UpdatePostageObject(selectedOrders, props.postage);
            console.log('switchCourierCallBack updateObj : ', updateObj);
            PostageService.UpdatePostage(updateObj).then((response) => {
                console.log('PostageService.UpdatePostage response', JSON.stringify(response));
                if (response.status === 200) {
                    setCommonProgressModalTitle('');
                    setCommonProgressModal(false);
                    setAlertModalMessage("Update postage success");
                    setShowAlertModal(true);
                    rootprops.callBack({ source:'postageUpdate',axRefresh : false, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});
                    ClearCheckBoxHeader();
                    console.log('PostageService.UpdatePostage success', JSON.stringify(response));
                } 
                else if (response.status===401)
                {
                dispatch(allActions.userActions.logOut());
                sessionService.SetSessionExpiry();
                }
                else if (response.response.status===401)
                {
                console.log('PostageService.UpdatePostage response.response.status===401', JSON.stringify(response.response));
                dispatch(allActions.userActions.logOut());
                sessionService.SetSessionExpiry();
                }
                else {
                    console.log('PostageService.UpdatePostage error', JSON.stringify(response));
                }
            })
        }
    }



    const ManifestModalCallBack = () => {
        console.log('inside ManifestModalCallBack');
        setShowManifestResponse(false);
      }
      


  const refreshButtonHandler = () =>{
      console.log('inside refreshButtonHandler');
    setStartDate(datePickerStartDate);
    setEndDate(datePickerEndDate);
     rootprops.callBack({axRefresh : refreshCheckBox, startDate : datePickerStartDate, endDate : datePickerEndDate, courierFromStore : courierFromStore});

  }
  const closeCancelModel =(props)=>{
    console.log('ShipmentGrid closeCancelModel props',props);
    if (props.action === CLICK_ACTION.CLOSE &&  (props.source === CLICK_ACTION.ALERTMODALCLOSE  || props.source === CLICK_ACTION.ALERTMODALOK))
    {
       setShowAlertModal(false);
    }
 }

 

    return (
          <Fragment> 
          <div className="col-sm"><SwitchCourierDropdown shipmentActionCallback={switchCourierCallBack}   ></SwitchCourierDropdown>{/* <span className="badge badge-pill badge-dark">Total Orders:{totalShipments}</span> */}</div>
          <div className="col-sm">     
          <div className="d-flex align-items-center"> <LocationFilter   selected={locationFromStore}   callBack={LocationfilterHandler}  ></LocationFilter> </div>
          </div>
          <div className="col-"> <div className="d-flex align-items-start" >Start Date : </div></div>
          <div className="col-sm"><DatePicker selected={datePickerStartDate} filterDate={maxDate => { return new Date() > maxDate }} dateFormat="dd/MM/yyyy" onChange={date => setDatePickerStartDate(date)}  > </DatePicker></div>
          <div className="col-"> <div className="d-flex align-items-start" >End Date : </div></div>
          <div className="col-sm">  <DatePicker selected={datePickerEndDate} filterDate={maxDate => { return new Date() > maxDate }} dateFormat="dd/MM/yyyy" onChange={date => setDatePickerEndDate(date)}  > </DatePicker></div>
          <div className="col-"><span className="d-inline-block"  data-toggle="tooltip"  title="if check box is checked data will be refreshed from AX, process will take more time"> <FontAwesomeIcon icon={faQuestionCircle} size="1x"></FontAwesomeIcon></span></div>
          <div className="col-"> <div><Checkbox  id={"refreshAxChk"}   isSelected={refreshCheckBox} onCheckBoxChange={RefreshAxChkHandler}  /></div></div>
          <div className="col"> 
          <button type="button" onClick={refreshButtonHandler} className="btn btn-dark btn-sm" >Refresh</button></div>                 
                           
          <div className="col-sm">
            {showManifestFromStore && (<button type="button" onClick={manifestButtonHandler} id="buttonCreateManifest" className="btn btn-dark btn-sm float-right" >Create Manifest</button>)}
            <CreateManifestModal
              show={showManifestModal}
              onClick={manifestModalCallBack}
              onHide={manifestModalCallBack}
              details={manifestDetails}
            >
            </CreateManifestModal>
            <ManifestResponseModal show={showManifestReponseModal}
              message={manifestResponseMessage}
              location={locationFromStore}
              courier ={courierFromStore}
              closeModal={ManifestModalCallBack}
            >
            </ManifestResponseModal>
            <PostageUpdateModal show={showPostageUpdateModal}  
            message ={postageUpdateModalMsg}
            PostageUpdateModalCallBack = {PostageUpdateModalCallBack}
            postageName={postageSelected}
            >
            </PostageUpdateModal>
            <CommonProgressModal title={commonProgressModalTitle}  show={showCommonProgressModal} >
            </CommonProgressModal>
            {showSubmitPickUpModal &&  <SubmitPickUpModal show={showSubmitPickUpModal}   
             shipments = {submitPickUpData}
             callBack={SubmitPickUpCallBack}  
             onHide={SubmitPickUpCallBack}>
             </SubmitPickUpModal>}

             {showProcessingModal && (<ProcessingModal show={showProcessingModal} title={processingModalMsg}  ></ProcessingModal>)}
             {showAlertModal && <AlertModal show={showAlertModal} message={alertModalMessage} closeModal={closeCancelModel}   ></AlertModal>}
             { showError && <ErrorModal show={showError}  message={errorMessage} closeModal={closeCancelModal}   ></ErrorModal>}
          </div>       
          </Fragment>
      
    )
}
export default ShipmentActionBar;