import { Fragment, useEffect } from "react";
import React, {useState}from 'react';
import Checkbox from  '../CustomControl/Checkbox';
import ProcessingModal from '../CustomControl/ProcessingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {faFilePdf}   from '@fortawesome/free-regular-svg-icons';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';
import './ManifestShipmentGrid.css'
const ManifestShipmentGrid =(props)=>
{

 console.log('props.shipmentSummary',props.shipmentSummary);

 console.log('entered  ManifestGrid');
 const[ isLoading, setIsLoading] = useState(false);
 
 const CheckBoxChangeHandler =(manifest)=>{
console.log('CheckBoxChangeHandler manifest',manifest);
 }

 
    const renderTableHeader =()=> {
    return (
       <Fragment>
          {/* <th><Checkbox  ></Checkbox></th> */}
          <th>#</th>
          <th>Manifest#</th>
          <th>Customer Account</th>
          <th>Invoice Number</th>
          <th>SalesShipment#</th>
          <th>Customer Name</th>
          <th>Total Cost</th>
          <th>Postage Code</th>
          <th>Label</th>
       </Fragment>)
       }

      
 const renderTableData =()=> {
    return props.shipmentSummary.map((shipment, index) => {
       const {customerAccount, createdDate,postageCode, manifestId, totalCost, salesShipmentNum, invoiceNumber,customerName, labelUrl } = shipment //destructuring
       console.log('renderTableData props.shipmentSummary',props.salesShipmentNum);
       return (
          <tr scope="row" key={salesShipmentNum} >
            {/*  <td scope="col"><Checkbox  onCheckBoxChange={()=>CheckBoxChangeHandler(manifestid)}  ></Checkbox></td> */}
            <td scope="col">{ index+1}</td>
            <td scope="col">{manifestId}</td>
            <td scope="col">{customerAccount}</td>
            <td scope="col">{invoiceNumber}</td>
            <td scope="col">{salesShipmentNum}</td>
            <td scope="col">{customerName}</td>
            <td scope="col">{totalCost}</td>
            <td scope="col">{postageCode}</td>
            <td scope="col">
            <a href={labelUrl}   target="_blank"> <FontAwesomeIcon icon={faFilePdf} color="red"  ></FontAwesomeIcon> </a>
            </td>
          </tr>
       )
    })
 }



 return (
       <div>
          {!isLoading ?
                   (
             <div className="table-responsive gridHolderSummary">
                   <table id="ManifestShipmentGrid" className="table table-striped ManifestShipmentGrid">
                         <thead>
                         <tr >{renderTableHeader()}</tr>
                         </thead>
                         <tbody    >
                            {renderTableData()}
                         </tbody>
                      </table>
                  
             </div>
              ) : (
             /*     <Fragment>
                <p>Loading... Please wait</p>
                <BtProgressBar  variant="success" now={100}  label={"Fetching shipments......."}  ></BtProgressBar>
                </Fragment> */
                <Fragment>
                  {/*  <ProcessingModal show={isLoading} title="Fetching shipments........"  ></ProcessingModal> */}
                </Fragment>
             )
          }
          </div>
    )
}
export default ManifestShipmentGrid;